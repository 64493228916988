import { ConstraintSvcClient, FleetMgtSvcClient, FleetMgtSvcClientV2, Stack } from '@jarvis/web-stratus-client';
import { useContext, useRef } from 'react';
import { shellProps } from '../../src/shellProps';
import { service } from '../../src/utils/constants';

const apiController = () => {
  const { stack, authProvider } = useContext(shellProps);

  const getStackPrefix = (stack: Stack): string => {
    switch (stack) {
      case Stack.dev:
        return 'dev';
      case Stack.pie:
        return 'pie';
      case Stack.stage:
        return 'stage';
      case Stack.prod:
        return '';
    }
  };

  const getApiUrl = (basePath: string): string => {
    let stackPrefix = getStackPrefix(stack);
    return (
      'https://' + (stackPrefix.length ? stackPrefix + '-' : '') + 'us1.api.ws-hp.com' + basePath
    );
  };

  const getApiClient = (serviceName) => {
    let client;

    switch (serviceName) {
      case service.fleetMgtV:
        client = useRef<FleetMgtSvcClient>(null);
        client.current = new FleetMgtSvcClient(stack, authProvider);
        break;

      case service.fleetMgtV1:
        client = useRef<FleetMgtSvcClient>(null);
        client.current = new FleetMgtSvcClient(getApiUrl('/fleetmgt/v1'), authProvider);
        break;

      case service.fleetMgtV2:
        client = useRef<FleetMgtSvcClient>(null);
        client.current = new FleetMgtSvcClientV2(getApiUrl('/fleetmgt/v2'), authProvider);
        break;

      case service.constraint:
        client = useRef<ConstraintSvcClient>(null);
        client.current = new ConstraintSvcClient(stack, authProvider);
    }

    return client.current;
  };

  return {
    getApiClient,
  };
};

export default apiController;
