import React, { useContext, useEffect, useState, memo } from 'react'
import { Button } from '@veneer/core'
import settingsContext from 'context/settings/settingsContext'
import configContext from 'context/config/configContext'
import { ObjectAttributeDescription } from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import ReferenceDeviceModal from 'components/policies/modal/ReferenceDevice'
import {
  HomeScreenAppsEnum,
  LauncherTypeEnum
} from 'components/policies/settings/attributes/device/controls/HomeScreenApps/HomeScreenAppsMenu'
import SystemLaunchers from 'components/policies/settings/attributes/device/controls/HomeScreenApps/SystemLaunchers'
import LabelSelect from 'common/controls/labelSelect'
import PreviewItem from '../../previewItem'
import { FlexRow, FlexColumn } from 'styles/styles'

export enum HomeScreenCustomEnum {
  ID = 'id',
  NAME = 'name'
}

const HomeScreenCustomControl = (props) => {
  const description: ObjectAttributeDescription = props.description
  const {
    id,
    attributes,
    compliance,
    localizationPath,
    onAttributeChange,
    data: { deviceSettings }
  } = props
  const { attribute } = description

  const { tt } = useContext(configContext)
  const {
    isEnabled,
    addDisabled,
    removeDisabled,
    displayAllErrors,
    addError,
    removeError
  } = useContext(settingsContext)

  const getLocalized = (key: string, params?): string =>
    tt(localizationPath, key, params)

  const getAppName = (x) => {
    const key =
      SystemLaunchers.launcherKeys[x[HomeScreenCustomEnum.ID]?.toLowerCase()]
    return key ? getLocalized(key) : x[HomeScreenCustomEnum.NAME]
  }

  const getAppItems = (x) => {
    const value = x[HomeScreenCustomEnum.ID]
    return value ? [{ value, label: getAppName(x) }] : []
  }

  const [value, setValue] = useState([
    PoliciesHelper.getData(description, deviceSettings)[HomeScreenCustomEnum.ID]
  ])
  const [options, setOptions] = useState(
    getAppItems(PoliciesHelper.getData(description, deviceSettings))
  )

  const [showModal, setShowModal] = useState(false)
  const [error, setError] = useState(false)
  const [displayError, setDisplayError] = useState(displayAllErrors)

  const enabled = isEnabled(attribute)
  const showError = displayError || displayAllErrors

  useEffect(() => {
    const err = enabled && !value.toString()
    setError(err && showError)
    err ? addError(id, attribute, showError) : removeError(id, attribute)
  }, [enabled, value, showError])

  useEffect(() => {
    const item = PoliciesHelper.getData(description, deviceSettings)
    setValue([item[HomeScreenCustomEnum.ID]])
    const found = options.find((x) => x.value === item[HomeScreenCustomEnum.ID])
    if (!found) {
      setOptions([...options, ...getAppItems(item)])
    }
  }, [deviceSettings])

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const val = PoliciesHelper.getData(description, deviceSettings)
    if (attributes && val[HomeScreenCustomEnum.ID] === value.toString()) {
      PoliciesHelper.update(
        description,
        val,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  const onChange = (v) => {
    const val = v.toString()
    setDisplayError(true)
    setValue([val])

    const selVal = options.find((x) => x.value === val)
    if (selVal) {
      const settings = [...deviceSettings]
      const newValue = {
        [HomeScreenCustomEnum.ID]: selVal.value,
        [HomeScreenCustomEnum.NAME]: selVal.label
      }
      PoliciesHelper.setData(description, settings, newValue)
      onAttributeChange({ ...props.data, deviceSettings: settings })
    }
  }

  const onNewOptions = (apps) => {
    const opt = (x) => ({
      value: x[HomeScreenAppsEnum.ID],
      label: getAppName(x)
    })
    setOptions(
      apps.reduce((acc, page) => {
        page.forEach((x) => {
          if (x[HomeScreenAppsEnum.TYPE] === LauncherTypeEnum.APP) {
            acc.push(opt(x))
          } else if (x[HomeScreenAppsEnum.APPS]) {
            x[HomeScreenAppsEnum.APPS].forEach((pg) => {
              pg.forEach((app) => {
                acc.push(opt(app))
              })
            })
          }
        })
        return acc
      }, [])
    )
  }

  return (
    <>
      {onAttributeChange ? (
        <FlexColumn>
          <FlexRow className={'marginBottom12'}>
            <Button
              appearance={'secondary'}
              id={'id-home-screen-custom-device'}
              onClick={() => setShowModal(true)}
            >
              {getLocalized('select-device')}
            </Button>
          </FlexRow>
          <LabelSelect
            label={getLocalized('app')}
            disabled={!enabled}
            className={'maxTextWidth'}
            options={options}
            onChange={({ value: v }) => onChange(v)}
            placeholder={getLocalized('common.select-option')}
            id={attribute}
            value={value}
            error={error}
            helperText={
              error ? getLocalized('common.errors.not-selected') : null
            }
          />
        </FlexColumn>
      ) : (
        <PreviewItem
          label={`${localizationPath}.app`}
          value={options[0]?.label}
          compliance={compliance}
        />
      )}
      {showModal && (
        <ReferenceDeviceModal
          attributeId={id}
          onChange={onNewOptions}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  )
}

export default memo(HomeScreenCustomControl)
