import React, { useContext } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'

import { ReportDetailsAccordion } from '../ReportDetailsAccordion'
import { ReportDetailsDesc } from '../ReportDetailsDesc'
import { ReportDetailsHeader } from '../ReportDetailsHeader'

import * as Wrapper from './styles'
import { IconInfo } from '@veneer/core'
import { Tooltip } from '@veneer/core'
import {
  getDateAndTime,
  getdownloadDateFormat,
  useDeviceView
} from '../../../../utils/commonMethods'
import GeneralContext from '../../../../contexts/GeneralContext'

export const ReportDetailsCard = (props) => {
  const { t } = useI18n()
  const { locale } = useContext(GeneralContext)
  const { isMobileView } = useDeviceView()
  const { reportId, reportName, fileName, generatedTime, category, numDays } =
    props.reportDetails || {}
  const { exportData, headers, customerName } = props

  const hasTooltip = [
    'devicesRemediationSummary',
    'devicesRecommendationSummary',
    'devicesAssessmentSummary'
  ]

  const downloadData = [
    {
      id: reportId,
      type: reportName,
      category: category,
      noOfDays: numDays,
      generatedTime: generatedTime,
      name: fileName || reportName,
      customerName: customerName
    }
  ]

  const reportDatas = [
    {
      title: t('ecp-global-reports.reportDetails.report', 'Report Type'),
      desc: (
        <Wrapper.ReportDetailsTooltipMobile>
          {t(`ecp-global-reports.reportsType.${reportName}`, '')}{' '}
          {hasTooltip.includes(reportName) && (
              <Tooltip
                content={t(
                  `ecp-global-reports.tooltip.summary`,
                  'To see a more detailed report, select individual device from the summary list.'
                )}
                id="tooltip"
                placement={isMobileView ? "top" : "trailing"}
              >
                <IconInfo size={24} color="colorGray5" />
              </Tooltip>
            
          )}
        </Wrapper.ReportDetailsTooltipMobile>
      )
    },
    {
      title: t('ecp-global-reports.reportDetails.generated', 'Generated'),
      desc: getDateAndTime(generatedTime || null, locale)
    },

    {
      title: t('ecp-global-reports.reportDetails.deviceGroup', 'Device Group'),
      desc: props.deviceGroupData
    },
    {
      title: t('ecp-global-reports.reportDetails.customer', 'Customer'),
      desc: customerName
    }
  ]
  if (reportName === 'jobSummary') {
    reportDatas.splice(3, 0, {
      title: t('ecp-global-reports.reportDetails.reportRange', 'Report Range'),
      desc: `${t(
        'ecp-global-reports.generate.reportRange.last',
        'last'
      )} ${numDays} ${t(
        'ecp-global-reports.generate.reportRange.days',
        'days'
      )}`
    })
  }
  const content = [
    {
      header: {
        centralArea: (
          <ReportDetailsHeader
            title={fileName || reportName}
            downloadData={downloadData}
            hideButton={false}
            showXlsx={true}
            exportData={exportData}
            headers={headers}
            fileName={
              fileName?.replace('.', '').substring(0, 185) +
              `_${getdownloadDateFormat(generatedTime)}`
            }
          />
        )
      },
      content: (
        <Wrapper.ReportDetailsDescContent>
          <ReportDetailsDesc reportDatas={reportDatas} imgDisplay={props.imgDisplay} />
        </Wrapper.ReportDetailsDescContent>
      ),
      expanded: true,
      id: 'reports_Details_Accordion'
    }
  ]
  return (
    <ReportDetailsAccordion
      data-testid="device_Details_Card_Content"
      content={content}
    />
  )
}
