import React, { useContext, useEffect, useState, useMemo } from 'react'
import {
  Modal,
  Search,
  Table,
  ButtonGroup,
  Button,
  ColumnIndexTypes,
  TableSortBy,
  SortTypes
} from '@veneer/core'
import collectionsContext from 'context/devices/collectionsContext'
import policiesContext from 'context/policies/policiesContext'
import configContext from 'context/config/configContext'
import PolicyConstraints from 'context/constraints/Constraints'
import { abcSort, TABLE_CONSTANTS, TABLE_PAGE_SIZES } from 'common/utilities'
import { getDecoratedName } from 'common/decoratedName'
import {
  LauncherTypeEnum,
  HomeScreenAppsEnum
} from 'components/policies/settings/attributes/device/controls/HomeScreenApps/HomeScreenAppsMenu'
import { ModalTableHeight } from 'styles/styles'

// import { HomeScreenApp } from './fleet-common-ui-lib.mjs'
const cdmToHomeScreen = (pages, entry = 'launchers') =>
  pages?.map((page) => {
    const launchers = page[entry] || page
    return launchers.map((launcher) => {
      const app = launcher.launcherType !== 'folder'
      const x = app ? launcher.application ?? launcher : launcher.folder
      const res = { [HomeScreenAppsEnum.VISIBLE]: x.visible === 'true' }
      if (app) {
        res[HomeScreenAppsEnum.TYPE] = LauncherTypeEnum.APP
        res[HomeScreenAppsEnum.ID] = x.shortcutId
        res[HomeScreenAppsEnum.NAME] = x.localizedShortcutName
      } else {
        res[HomeScreenAppsEnum.TYPE] = LauncherTypeEnum.FOLDER
        res[HomeScreenAppsEnum.ID] = x.systemFolder === 'true' ? x.id : null
        res[HomeScreenAppsEnum.NAME] = x.localizedTitle
        res[HomeScreenAppsEnum.APPS] = cdmToHomeScreen(
          x.folderPages,
          'applications'
        )
      }
      return res
    })
  }) ?? []

enum RefDeviceEnum {
  MODEL_NAME = 'modelName',
  SERIAL_NUMBER = 'serialNumber',
  IP_ADDRESS = 'ipAddress',
  DEVICE_NAME = 'deviceName',
  GROUP = 'group'
}

const defPageSize = 25
const defSortOrder: SortTypes = 'ascending'
const defSorting: TableSortBy = {
  id: RefDeviceEnum.MODEL_NAME,
  type: defSortOrder
}

const ReferenceDeviceModal = (props) => {
  const { attributeId, onChange, onClose } = props
  const [tableData, setTableData] = useState(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [sorting, setSorting] = useState(defSorting)
  const [searchValue, setSearchValue] = useState('')
  const [pageSize, setPageSize] = useState(defPageSize)
  const [loading, setLoading] = useState(false)

  const { tt, isWex } = useContext(configContext)
  const { devices, resources, getDevices, getResources } =
    useContext(collectionsContext)
  const { showError } = useContext(policiesContext)

  const getLocalized = (key: string, params?) =>
    tt(`policy.settings.reference-device`, key, params)

  useEffect(() => {
    getDevices((error) => showError(error))
  }, [])

  const tableColumns = useMemo(() => {
    const id = 'id'
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id, label: id, index },
      {
        id: RefDeviceEnum.MODEL_NAME,
        label: getLocalized('model-name'),
        sortable: true
      },
      {
        id: RefDeviceEnum.SERIAL_NUMBER,
        label: getLocalized('serial-number'),
        sortable: true
      },
      {
        id: RefDeviceEnum.IP_ADDRESS,
        label: getLocalized('ip-address'),
        sortable: true
      },
      {
        id: RefDeviceEnum.DEVICE_NAME,
        label: getLocalized('device-name'),
        sortable: true
      },
      {
        id: RefDeviceEnum.GROUP,
        label: getLocalized('group'),
        sortable: true
      }
    ]
  }, [])

  const handlePageChange = (page) => setCurrentPage(page - 1)
  const handlePageSizeChange = (_, option) => setPageSize(option.value)

  useEffect(() => {
    const address = (adapters, type) =>
      adapters?.find((adapter) => adapter[type]?.enabled)?.[type].address?.ip
    const item = (device) => ({
      id: device.deviceId,
      modelName: device.identity?.makeAndModel?.name || TABLE_CONSTANTS.NO_DATA,
      serialNumber: device.identity?.serialNumber || TABLE_CONSTANTS.NO_DATA,
      ipAddress:
        address(device.network?.adapters, 'ipv4') ||
        address(device.network?.adapters, 'ipv6') ||
        TABLE_CONSTANTS.NO_DATA,
      deviceName: device.identity?.friendlyName || TABLE_CONSTANTS.NO_DATA,
      group: device.groups?.length
        ? device.groups.map((group) => group.label).join(TABLE_CONSTANTS.JOINER)
        : TABLE_CONSTANTS.NO_DATA,
      rowConfig: { selected: false }
    })
    setTableData(
      devices
        ? devices
            .filter((device) => {
              const modelNumber = device.identity?.makeAndModel?.number
              return modelNumber
                ? new PolicyConstraints(modelNumber).isPolicyAttributeSupported(
                    attributeId
                  )
                : true
            })
            .map((device) => item(device))
        : null
    )
  }, [devices])

  const selected = useMemo(
    () => tableData?.find((row) => row.rowConfig.selected),
    [tableData]
  )

  const handleSaveButtonAction = () => {
    if (selected) {
      setLoading(true)
      getResources(selected.id, (error) => showError(error))
    }
  }

  useEffect(() => {
    if (resources && loading) {
      setLoading(false)
      const cdmData =
        resources[
          'com.hp.cdm.service.controlPanel.version.1.resource.homeScreen'
        ]?.['cdmData']
      if (cdmData) {
        // const data = HomeScreenApp.cdmDataToPolicy({ cdmData })
        const data = cdmToHomeScreen(cdmData.pages)
        if (data) {
          onChange(data)
          onClose()
          return
        }
      }
      showError({ text: 'error.code.home-screen' })
    }
  }, [resources, loading])

  const filteredData = useMemo(() => {
    const searchLowerCase = searchValue.toLowerCase()
    return !tableData
      ? null
      : searchLowerCase.length
      ? tableData.filter(
          (item) =>
            item.modelName.toLowerCase().includes(searchLowerCase) ||
            item.serialNumber.toLowerCase().includes(searchLowerCase) ||
            item.ipAddress.toLowerCase().includes(searchLowerCase) ||
            item.deviceName.toLowerCase().includes(searchLowerCase) ||
            item.group.toLowerCase().includes(searchLowerCase)
        )
      : tableData
  }, [tableData, searchValue])

  const sortedData = useMemo(() => {
    const picker = () => {
      switch (sorting.id) {
        case RefDeviceEnum.SERIAL_NUMBER:
          return (x) => x.serialNumber
        case RefDeviceEnum.IP_ADDRESS:
          return (x) => x.ipAddress
        case RefDeviceEnum.DEVICE_NAME:
          return (x) => x.deviceName
        case RefDeviceEnum.GROUP:
          return (x) => x.group
        default:
          // RefDeviceEnum.MODEL_NAME:
          return (x) => x.modelName
      }
    }
    return !filteredData
      ? null
      : abcSort([...filteredData], picker(), sorting.type)
  }, [filteredData, sorting])

  const pagedData = useMemo(() => {
    const getDataForTable = (item, search) => {
      const getDecoratedText = (text) => {
        const decoratedName = getDecoratedName(text, search, isWex)
        return decoratedName.length ? <div>{decoratedName}</div> : text
      }
      return {
        id: item.id,
        [RefDeviceEnum.MODEL_NAME]: getDecoratedText(
          item[RefDeviceEnum.MODEL_NAME]
        ),
        [RefDeviceEnum.SERIAL_NUMBER]: getDecoratedText(item.serialNumber),
        [RefDeviceEnum.IP_ADDRESS]: getDecoratedText(item.ipAddress),
        [RefDeviceEnum.DEVICE_NAME]: getDecoratedText(item.deviceName),
        [RefDeviceEnum.GROUP]: getDecoratedText(item.group),
        rowConfig: item.rowConfig
      }
    }

    // Change current page if required
    const lastPage =
      sortedData && sortedData.length
        ? ~~((sortedData.length - 1) / pageSize)
        : 0
    if (currentPage > lastPage) {
      setCurrentPage(lastPage)
    }

    // Paged
    const searchLowerCase = searchValue.toLowerCase()
    return !sortedData
      ? null
      : [...sortedData]
          .slice(currentPage * pageSize, currentPage * pageSize + pageSize)
          .map((item) => getDataForTable(item, searchLowerCase))
  }, [sortedData, pageSize, currentPage])

  const onTableClick = (me) => {
    if (pagedData) {
      let rowId = null
      document.querySelectorAll(`.hideFirstCol tbody tr`).forEach((tr, i) => {
        const rect = tr.getBoundingClientRect()
        if (me.clientY >= rect.y && me.clientY < rect.bottom) {
          rowId = pagedData[i].id
        }
      })
      if (rowId) {
        tableData.forEach((row) => (row.rowConfig.selected = row.id === rowId))
        setTableData([...tableData])
      }
    }
  }

  return (
    <Modal
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      title={getLocalized('select-device')}
      className={'extra-large-policy-modal'}
      data-testid={'id-reference-device-modal'}
      footer={
        <ButtonGroup>
          <Button
            id={'selectBtn'}
            onClick={handleSaveButtonAction}
            disabled={!selected || loading}
          >
            {getLocalized('common.select')}
          </Button>
          <Button id={'cancelBtn'} appearance={'secondary'} onClick={onClose}>
            {getLocalized('common.cancel')}
          </Button>
        </ButtonGroup>
      }
    >
      <p className={'paddingBottom16'}>{getLocalized('modal-description')}</p>
      <Search
        id={'device-search'}
        placeholder={'Search'}
        className={'maxSearchWidth paddingBottom16'}
        value={searchValue}
        onChange={(value) => setSearchValue(value)}
      />
      <ModalTableHeight
        className={'hideFirstCol'}
        onClick={onTableClick}
        data-testid={'id-reference-device-table-container'}
      >
        <Table
          columns={tableColumns}
          data={pagedData || []}
          onSort={(_, sortBy) => setSorting(sortBy)}
          loading={pagedData === null || loading}
          loadingDataLength={pageSize}
          rowSelector={'multiSelection'}
          pagination={{
            currentPage: currentPage + 1,
            pageSize,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange,
            totalItems: filteredData ? filteredData.length : 0,
            pageSizeOptions: TABLE_PAGE_SIZES
          }}
          preferences={{
            sortBy: sorting,
            width: [
              { columnId: RefDeviceEnum.MODEL_NAME, width: 200 },
              { columnId: RefDeviceEnum.SERIAL_NUMBER, width: 140 },
              { columnId: RefDeviceEnum.IP_ADDRESS, width: 140 },
              { columnId: RefDeviceEnum.DEVICE_NAME, width: 200 },
              { columnId: RefDeviceEnum.GROUP, width: 160 }
            ]
          }}
          data-testid={'id-reference-device-table'}
        />
      </ModalTableHeight>
    </Modal>
  )
}

export default ReferenceDeviceModal
