export enum ConstraintsSubFeatureIdEnum {
  Asset_Number = 'astn_assetnumber',
  Company_Name = 'comnm_companyname',
  Contact_Person = 'cntpe_contactperson',
  CORS_Enabled = 'cors_enablecors',
  CORS_TrustedSites = 'cors_viewtrustedsite',
  AccessControlDefault = 'access-control_defaultControlPannel',
  Permission_Sets  = 'permission-sets',
  AccessControlAlternateSignInMethod = "alternate-sign-in-method",
  Device_Location = 'deloc_devicelocation',
  Device_Name = 'denam_devicename',
  Duplex_Binding = 'dplxbnd_duplexbinding',
  Tray_Admin = 'tray-admin',
  Control_Panel_Language = 'ctrlng_controlpanellanguage',
  // Home_Screen_App_Fs4 = 'home-screen-app-fs4',
  // Home_Screen_Custom = 'home-screen-custom',
  Home_Screen_Language = 'home-screen-language-enabled',
  Home_Screen_AlternateKeyboard = 'home-screen-alternate-keyboard',
  Date_Time_Format = 'date-time-format',
  Date_Format = 'dtf_dateformat',
  Date_And_Time = 'dat_systemTime',
  Time_Format = 'dtf_timeformat',
  Time_Zone = 'tzds_timezone',
  Sleep_Delay = 'sldly_sleepdelay',
  Sleep_Schedule_Weekly = 'slpschedule_sleep',
  Sleep_Schedule_Holiday = 'slpschedule_holiday',
  Sleep_Settings_Sleep_Mode_Auto_Off_Timer = 'slpsetting_enable',
  Sleep_Settings_Sleep_Mode = 'slpsetting_sleepMod',
  Sleep_Settings_Auto_On_Events = 'slpsetting_events',
  Sleep_Settings_Auto_Off_After_Sleep = 'slpsetting_aofas',
  Default_Media_Size = 'def-media-size',
  Default_Media_Type = 'def-media-type',
  // Def_Print_Options = 'def-print-options',
  // Delay_Low_Supply_Msg = 'delay-low-supply-msg',
  Cartridge_Policy = 'cartp_cartridgepolicy',
  Cartridge_VeryLowAction_Black = 'cartridge-verylowaction-black',
  Cartridge_VeryLowAction_Color = 'cartridge-verylowaction-color',
  // Device_User_Accounts = 'device-user-accounts',
  Bt_Low_Energy = 'bt-low-energy',
  Ftp_print = 'ftp_enable',
  EWS_Config = 'ews_Config',
  Primary_Dns_Server = 'dnssrv_primarydnsip',
  Secondary_Dns_Server = 'dnssrv_secondarydnsip',
  Domain_Name = 'dnssrv_domainname',
  IP_Config_Precedence = 'ipv6info_configPrecedence',
  Ipv4_Info = 'ipv4info_ipv4information',
  Ipv6_Info = 'ipv6info_enabledipv6',
  Subnet_mask = 'ipvinfo_subnetmask',
  Ipv6_DhcpPolicy = 'ipv6info_dhcpv6Policy',
  Gate_way = 'ipvinfo_gateway',
  Ipv6_DomainName = 'ipv6info_domainName',
  Ipv6_PrimaryDNS = 'ipv6info_primaryIpv6dns',
  Ipv6_SecondaryDNS = 'ipv6info_secondaryIpv6dns',
  ipv6_configurationprecedence = 'ipv6info_configPrecedence',
  Link_Setting = 'lnkset_linksetting',
  // Snmp_Traps = 'snmp-traps',
  // Nw_Features = 'nw-features',
  Ews_Language = 'ewsls_languagesettings',
  Ews_Language_Source = 'ewsls_languagesource',
  TimeServices_DriftCorrection = 'timeServices_DriftCorrection',
  TimeServices_ServerAddress = 'timeServices_ServerAddress',
  TimeServices_port = 'timeServices_port',
  TimeServices_syncHours = 'timeServices_syncHours',
  // Ews_Access = 'ews-access',
  Ews_Proposed_Password = 'adewspwd_proposedpwd',
  Ews_Password_Set = 'adewspwd_pwdset',
  Ews_Admin_MinimumPasswordLength = 'adewspwd_minpwdlength',
  Ews_Admin_PasswordComplexity = 'adewspwd_pwdcomplexity',
  Ews_Admin_PasswordAccountLockout = 'adewspwd_acclockout',
  Ews_Admin_PasswordMaximumAttempts = 'adewspwd_maxattempts',
  Ews_Admin_PasswordResetAfterSecs = 'adewspwd_resetattemptsaftersecs',
  Ews_Admin_PasswordLockDurationSecs = 'adewspwd_lockoutdurationsecs',
  SNMPV1V2_Enabled = 'snmpv1v2_enabled',
  SNMPV1V2_Access_Option = 'snmpv1v2_accessoption',
  SNMPV1V2_ReadOnly_CommunityName = 'snmpv1v2_readonlycommunityname',
  SNMPV1V2_WriteOnly_CommunityName = 'snmpv1v2_writeonlycommunityname',
  SNMPV1V2_ReadOnly_Public_Allowed = 'snmpv1v2_readonlypublicallowed',
  SNMPV1V2_ReadOnly_CommunityNameSet = 'snmpv1v2_readonlycommunitynameset',
  SNMPV1V2_WriteOnly_CommunityNameSet = 'snmpv1v2_writeonlycommunitynameset',
  SNMPV3_Enabled = 'snmpv3_enabled',
  SNMPV3_UserName = 'snmpv3_securityname',
  SNMPV3_MinimumPassword_Length = 'snmpv3_minpwdlength',
  SNMPV3_Account_Lockout = 'snmpv3_acclockoutenabled',
  SNMPV3_KeyType = 'snmpv3_keytype',
  SNMPV3_Maximum_Attempts = 'snmpv3_acclockoutmaxattempts',
  SNMPV3_Account_Reset_Lockout_Intervel = 'snmpv3_acclockoutresetlockoutcounterinterval',
  SNMPV3_Lockout_Duration = 'snmpv3_acclockoutlockoutinterval',
  SNMPV3_Password_Complexity = 'snmpv3_pwdcomplexityenabled',
  SNMPV3_Encryption_Algorithms = 'snmpv3_authalgo',
  SNMPV3_Privacy_Passphrase = 'snmpv3_privacypassphrase',
  SNMPV3_Authentication_Passphrase = 'snmpv3_authpassphrase',
  EWS_Admin_Password_Check = 'adewspwd_cfgbyuser',
  PJL_Password = 'pjlpwd_pjlpassword',
  LDAP_SignIn = 'ldapsis_enb',
  LDAP_Server_Address = 'ldapsis_sveradd',
  LDAP_Port_Number = 'ldapsis_sverport',
  LDAP_Use_SSL = 'ldapsis_ssl',
  LDAP_Server_Authentication = 'lldapsis_ldapAdminCred',
  LDAP_Use_MFP_Credentials = 'ldapsis_mfpcred',
  LDAP_Bind_Prefix = 'ldapsis_bindprefix',
  LDAP_Use_Admin_Credentials = 'ldapsis_ldapAdminCred',
  LDAP_Admin_DN = 'ldapsis_AdminDN',
  LDAP_Admin_Password = 'ldapsis_Adminpwd',
  LDAP_Bind_And_Search_Root = 'ldapsis_bindroots',
  LDAP_match_name_with_attribute = 'ldapsis_matchNameAttribute',
  LDAP_retrieve_user_email = 'ldapsis_retrieveEmailAttribute',
  LDAP_retrieve_user_name = 'ldapsis_retrievedeviceNameAttribute',
  LDAP_retrieve_user_group = 'ldapsis_retrievedeviceGroupAttribute',
  LDAP_exact_match_on_group_attribute = 'ldapsis_exactGrpMatch',
  Remote_Configuration_Password = 'rmcfgpwd_remoteconfigpwd',
  Remote_ConfigPasswordMinimumLength = 'rmcfgpwd_minpwdlength',
  Remote_ConfigPasswordComplexity = 'rmcfgpwd_pwdcomplexity',
  Remote_ConfigPasswordAccountLockout = 'rmcfgpwd_acclockout',
  Remote_ConfigPasswordMaximumAttempts = 'rmcfgpwd_maxattempts',
  Remote_ConfigPasswordResetAfterSecs = 'rmcfgpwd_resetattemptsaftersecs',
  Remote_ConfigPasswordLockoutDurationSecs = 'rmcfgpwd_lockoutdurationsecs',
  Service_Access_Code = 'saccd_serviceaccesscode',
  Airprint_fax = 'nwenbfeature_airprintFax',
  Airprint_scan = 'nwenbfeature_airprintScan',
  Airprint_secure_scan = 'nwenbfeature_airprintSecureScan',
  DHCPV4withRFC = 'dhccomp_dhcpcompliance',
  CA_Certificate = 'ca-certificate',
  ID_Certificate = 'id-certificate',
  EmailAddress_Restriction = 'eams_restrict',
  EmailAddress_MessageSetting = 'eams_frm',
  EmailAddress_AllowInvalidFormats = 'eams_allow_inv_email',
  EmailAddress_DglSignEmail = 'eams_dgtsign',
  EmailAddress_DglSignEmail_UserEditable = 'eams_dgtsigeml',
  Encrypt_EmailMsg = 'eams_encrmsg',
  Encrypt_EmailMsg_UserEditable = 'eams_enceml',
  Outgoing_Servers = 'outgngserv',
  Outgoing_Servers_ServerName = 'outgngserv_servAddr',
  Outgoing_Servers_Port = 'outgngserv_portNum',
  Outgoing_Servers_Email = 'outgngserv_sendToEmail',
  Outgoing_Servers_InternetFax = 'outgngserv_intFax',
  Outgoing_Servers_AutomatedEmail = 'outgngserv_emailAlert',
  Outgoing_Servers_AutoSend = 'outgngserv_emailAlert',
  Outgoing_Servers_Name = 'outgngserv_emailName',
  Outgoing_Servers_Email_Split = 'outgngserv_emailSplit',
  Outgoing_Servers_Ssl = 'outgngserv_ssl',
  Outgoing_Servers_Valid_Cert = 'outgngserv_validCert',
  Outgoing_Servers_Req_Auth = 'outgngserv_reqAuth',
  Outgoing_Server_CredType = 'outgngserv_credType',
  Outgoing_Server_User_Name = 'outgngserv_userName',
  Outgoing_Server_Password = 'outgngserv_password',
  Outgoing_Server_Send_Email = 'outgngserv_sendEmail',
  Outgoing_Server_Fax = 'outgngserv_fax',
  Outgoing_Server_Auto_Send = 'outgngserv_autoSend',
  Outgoing_Server_Alert = 'outgngserv_alert',
  SaveTo_SharePoint = 'sts_enable',
  SendTo_Email = 'ste_enable',
  SaveTo_NetworkFolder = 'stnf_enable',
  Smart_CloudPrint = 'smp',
  HPJetAdvMoreApps_Enabled = 'hpjma_hpjEnab',
  AccountCreation = 'hpjma_acc',
  Dot1x_Authentication_PeapEnabled = '802Auth_PEAP',
  Dot1x_Authentication_TlsEnabled = '802Auth_EapTls',
  Dot1x_Authentication_Username = '802Auth_Username',
  Dot1x_Authentication_Password = '802Auth_Password',
  Dot1x_Authentication_ServerId = '802Auth_serverId',
  Dot1x_Authentication_RequireExactMatch = '802Auth_requireExactMatch',
  Dot1x_Authentication_OnAuthenticationFailure = '802Auth_blockNetwork',
  Dot1x_Authentication_Reauthenticate = '802Auth_reauthenticate',
  Wifi_Direct_Enable = 'wifidirect_enable',
  Wifi_Direct_Suffix = 'wifidirect_suffix',
  Wifi_Direct_Connection = 'wifidirect_connection',
  Wifi_Direct_Password = 'wifidirect_pass',
  Wifi_Direct_Channel = 'wifidirect_channel_preferred',
  Wifi_Direct_Hide_SSID = 'wifidirect_hidessid',
  Wifi_Direct_Hide_Password = 'wifidirect_showPassphrase',
  App_Deployment_WorkPath_Enablement = 'wpe_workpathenablementstate',
  App_Deployment_AppInstallation = 'wpe_appInstalled',
  Restrict_Color = 'restrict-Color-Access',
  Restrict_User_Permissions = 'Restrict-Application-label',
  Restrict_Application = 'restrict-Application',
  Default_Permissions = 'default-permissions',
  Non_Default_Applications = 'non-default-applications',
  Stored_Data_Pin_Protection_Limit= 'stdpin_prot_limit',
  Stored_Data_Pin_Protection_RequireScanJobStorage = 'stdpin_prot_requireScanJobStorage',
  Stored_Data_Pin_Protection_Printdriverstoredjobs = 'stdpin_prot_printdriverstoredjobs',
  Stored_Data_Pin_Protection_CancelStoredJob = 'stdpin_prot_cancelStoredJob',
  Color_Copy_Mode = 'color-copy-mode',
  Copy_JobBuild = 'copy-job-build',
  HP_WebServices = 'webservices',
  Toner_Collection_Unit = 'toner_coll_uvf_action',
  Cartridge_Protection = 'cp_enabled',
  Home_Screen_Application = 'hme_scr_cust_fs_dflt_app',
  Emergency_Client_Mode = 'hme_scr_cust_fs_emrgy_clmode',
  Low_Threshold = 'ct_threshold',
  Use_Requested_Tray = 'usrrequtray_enable',
  Override_A4_Letter = 'ovral_enabled',
  Manual_Feed_Prompt = 'manual-feed-prompt',
  Tray1_Mode_Manual_feed = 'try_manual_feed_enable',
  System_Location = 'sysloc_systemlocation',
  System_Contact = 'syscon_systemcontact',
  Support_Contact = 'supcon_supportcontact',
  Size_Type_Prompt = 'size-type-prompt',
  ProxyServerEnabled= 'proxy-server',
  ProxyServerAddress = 'proxy-server-address',
  ProxyServerPort = 'proxy-server-port',
  ProxyServerUser = 'proxy-server-user',
  ProxyServerPassword = 'proxy-server-password',
  Bootloader_Password = 'btldr_pswdset',
  Bootloader_Current_Password = 'btldr_crntpswd',
  Bootloader_Proposed_Password = 'btldr_proppswd',
  Email_Scan_Settings = 'emscnstng_optimizetext',
  Delay_VeryLow_Message = 'dvlm_threshold'
}

export enum ConstraintsResourceEnum {
  Device_Configuration = 'device_configuration',
  JobTicketConfigDefaults_Print = 'jobTicketConfigurationDefaults_print',
  ControlPanel_Configuration = 'controlPanel_configuration',
  HomeScreen_Language = 'controlPanel_configuration',
  Clock_Configuration = 'clock_configuration',
  EWS_Configuration = 'ews_configuration',
  DeviceAdminConfig = 'deviceAdminConfig',
  RemoteConfig_Password = 'dssConfig',
  SNMPConfig = 'snmpConfig',
  AccountPolicy = 'accountPolicy',
  PowerConfig = 'power_configuration',
  SleepConfiguration = 'sleep_configuration',
  ServiceUserConfig = 'serviceUserConfig',
  Supply_configPvt = 'supply_configPvt',
  FaxSendConfig = 'faxSend_configuration',
  AapterConfigs = 'adapterConfigs',
  BluetoothConfig = 'ble_configuration',
  FtpPrinting = 'printServices',
  EWS_Config = 'ews_configuration',
  NetworkScanService = 'networkScanService',
  JobTicketConfigDefaults_ScanEmail = 'jobTicketConfigurationDefaults_scanEmail',
  JobTicketConfig_DefPrint = 'jobTicketConfigurationDefaults_print',
  PrintConfiguration = 'print_configuration',
  CA_Certificate = 'certificate_CA_configuration',
  ID_Certificate = 'certificate_ID_configuration',
  Email_SmtpServers = 'smtpServers',
  Scan_DestinationConfig = 'Scan_DestinationConfig',
  CorsConfig = 'systemConfig',
  FleetProxyLegacyAttributes = 'fleetProxy_legacyAttributes',
  LDAP_Configuration = 'ldapConfig',
  WifiDirect_Configuration = 'wifiDirectConfig',
  App_Deployment_WorkPath_Enablement = 'workPath_solutionManager_Configuration',
  App_Deployment_AppInstallation = 'workPath_solutionManager_Configuration',
  Restrict_Color = 'legacyAttributes',
  Media_Configuration = "media_configuration",
  StoredDataPin_Protection = 'storeJobManagement_configuration',
  ColorCopyMode = 'jobTicketConfigurationDefaults_copy',
  Copy_JobBuild = 'job_ticket_creation',
  HP_WebServicesResource = 'avatar_registration',
  accesControlDefault  = 'authConfig',
  Permission_sets = 'rbac_accessControl_configuration',
  Media_Handling_Configuration = "mediaHandling_configuration",
  JetDirectServices = 'jetDirectService',
  ManualFeed_Prompt  = 'mediaHandling_configuration',
  ProxyServer = 'proxy_config',
  Bootloader_Password = 'bootloader_password'
}

export enum ConstraintsSettingsNameEnum {
  Asset_Number = 'assetNumber',
  Company_Name = 'companyName',
  Contact_Person = 'companyContact',
  Device_Location = 'deviceLocation',
  Device_Name = 'deviceDescription',
  Duplex_Binding = 'dest.print.duplexBinding',
  // Tray_Admin = 'tray-admin',
  Control_Panel_Language = 'currentLanguage',
  CORS_Enabled = 'corsEnabled',
  CORS_TrustedSites = 'trustedSites',
  // Home_Screen_App_Fs4 = 'home-screen-app-fs4',
  // Home_Screen_Custom = 'home-screen-custom',
  // Home_Screen_Language = 'home-screen-language',
  Date_Time_Format = 'date-time-format',
  Date_Format = 'dateFormat',
  Time_Format = 'timeFormat',
  System_Time = 'systemTime',
  Time_Zone = 'timeZone',
  Dst_Enabled = 'dstEnabled',
  Sleep_Delay = 'sleep-delay',
  Sleep_Timeout = 'sleepTimeout',
  Sleep_Schedule_Weekly = 'sleepSchedules',
  Sleep_Schedule_Holiday = 'holidaySchedules',
  Sleep_InactivityTimeout = 'inactivityTimeout',
  Sleep_Settings_Sleep_Mode_Auto_Off_Timer = 'sleepAutoOffTimerEnabled',
  Sleep_Settings_Sleep_Mode = 'sleepModeTimeout',
  Sleep_Settings_Auto_On_Events = 'autoOnEvents',
  Sleep_Settings_Auto_Off_After_Sleep = 'autoOffTimeout',
  Supply_Policy = 'supplyPolicy',
  Cartridge_VeryLowAction_Black = 'blackVeryLowAction',
  Cartridge_VeryLowAction_Color = 'colorVeryLowAction',
  Delay_VeryLowMessaging = 'delayVeryLowMessaging',
  Def_Media_Size = 'dest.print.mediaSize',
  Def_Media_Type = 'dest.print.mediaType',
  // Def_Print_Options = 'def-print-options',
  // Delay_Low_Supply_Msg = 'delay-low-supply-msg',
  // Device_User_Accounts = 'device-user-accounts',
  Bt_Low_Energy = 'requestedBeaconingEnabled',
  Ftp_print = 'ftpPrint.enabled',
  EWS_Config = 'ewsEnabled',
  Primary_Dns_Server = 'eth.ipv4.dnsServer.primary.address',
  Secondary_Dns_Server = 'eth.ipv4.dnsServer.secondary.address',
  IPv6DhcpPolicy = 'eth.ipv6.dhcpv6Policy',
  IPv6DomainName = 'eth.identity.domainNameV6.name',
  IPv6_PrimaryDNS = 'eth.ipv6.dnsServer.primary.address',
  IPv6_SecondaryDNS = 'eth.ipv6.dnsServer.secondary.address',
  IPv6ConfigPrecedence = 'eth.ipConfigPrecedence',
  Domain_Name = 'eth.identity.domainName.name',
  IP_Config_Precedence = 'eth.ipConfigPrecedence',
  Ipv4_Info = 'eth.ipv4.address.ip',
  Ipv6_info = 'eth.ipv4.address.ip',
  Subnet_Mask = 'eth.ipv4.address.subnet',
  Gate_Way = 'eth.ipv4.address.gateway',
  // Ipv6_Info = 'ipv6-info',
  Link_Setting = 'eth.ethConfig.linkConfig',
  // Snmp_Traps = 'snmp-traps',
  // Nw_Features = 'nw-features',
  // Dhcp_v4_Compliance = 'dhcp-v4-compliance',
  Ews_Language = 'selectedLanguage',
  Language_Source = 'languageSource',
  TimeServices_systemTimeSync = 'systemTimeSync',
  TimeServices_serverAddress = 'ntpServer',
  TimeServices_Port = 'ntpLocalPortNumber',
  TimeServices_syncHours = 'ntpSyncFrequency',

  // Ews_Access = 'ews-access',
  Credentials_ProposedPassword = 'credentials.proposedPassword',
  Credentials_CurrentPassword = 'credentials.currentPassword',
  Ews_Admin_MinimumPasswordLength = 'minPasswordLength',
  Ews_Admin_PasswordComplexity = 'passwordComplexityEnabled',
  Ews_Admin_PasswordSet = 'passwordSet',
  Ews_Admin_PasswordAccountLockout = 'accountLockout.enabled',
  Ews_Admin_PasswordMaximumAttempts = 'accountLockout.maximumAttempts',
  Ews_Admin_PasswordResetAfterSecs = 'accountLockout.resetLockoutCounterInterval',
  Ews_Admin_PasswordLockDurationSecs = 'accountLockout.lockoutInterval',
  SNMPV1V2_Enabled = 'snmpV1V2Config.enabled',
  SNMPV1V2_Access_Option = 'snmpV1V2Config.accessOption',
  SNMPV1V2_ReadOnly_CommunityName = 'snmpV1V2Config.readOnlyCommunityName',
  SNMPV1V2_WriteOnly_CommunityName = 'snmpV1V2Config.writeOnlyCommunityName',
  SNMPV1V2_ReadOnly_Public_Allowed = 'snmpV1V2Config.readOnlyPublicAllowed',
  SNMPV1V2_ReadOnly_CommunityNameSet = 'snmpV1V2Config.readOnlyCommunityNameSet',
  SNMPV1V2_WriteOnly_CommunityNameSet = 'snmpV1V2Config.writeOnlyCommunityNameSet',
  SNMPV3_Enabled = 'snmpV3Config.enabled',
  SNMPV3_UserName = 'snmpV3Config.securityName',
  SNMPV3_MinimumPassword_Length = 'snmpV3Config.minPasswordLength',
  SNMPV3_KeyType = 'snmpV3Config.keyType',
  SNMPV3_Privacy_Algorithms = 'snmpV3Config.privacyAlgorithm',
  SNMPV3_Encryption_Algorithms = 'snmpV3Config.authenticationAlgorithm',
  SNMPV3_Account_Lockout = 'snmpV3Config.accountLockout.enabled',
  SNMPV3_Maximum_Attempts = 'snmpV3Config.accountLockout.maximumAttempts',
  SNMPV3_Account_Reset_Lockout_Intervel = 'snmpV3Config.accountLockout.lockoutInterval',
  SNMPV3_Lockout_Duration = 'snmpV3Config.accountLockout.resetLockoutCounterInterval',
  SNMPV3_Password_Complexity_Enabled = 'snmpV3Config.passwordComplexityEnabled',
  SNMPV3_Privacy_Passphrase = 'snmpV3Config.privacyPassphrase',
  SNMPV3_Authentication_Passphrase = 'snmpV3Config.authenticationPassphrase',
  Configured_ByUser = 'configuredByUser',
  RemoteConfigPassword = 'password',
  PJL_Password = 'pjlPassword',
  RemoteConfigPasswordConfigured = 'passwordConfigured',
  RemoteConfigPasswordMinimumLength = 'minPasswordLength',
  RemoteConfigPasswordComplexity = 'passwordComplexityEnabled',
  RemoteConfigPasswordAccountLockout = 'accountLockout.enabled',
  RemoteConfigPasswordMaximumAttempts = 'accountLockout.maximumAttempts',
  RemoteConfigPasswordResetAfter = 'accountLockout.resetLockoutCounterInterval',
  RemoteConfigPasswordLockoutDuration = 'accountLockout.lockoutInterval',
  SvcAccessCode = 'password',
  Airpint_Fax = 'ippFaxEnabled',
  Airpint_scan = 'scanServices.eSCL.eSCL',
  Airpint_Secure_Scan = 'scanServices.eSCL.eSCLSecure',
  DHCPv4withRFC = 'eth.ipv4.address.dhcpFqdnRfc4702Compliance',
  CA_Certificate_File = 'supportCACertificate',
  ID_Certificate_File = 'supportIDCertificate',
  ID_Certificate_Password = 'password',
  EmailAddress_Restriction = 'dest.email.addressFieldRestrictionsEnabled',
  EmailAddress_MessageSetting = 'dest.email.fromSignInRequired',
  EmailAddress_AllowInvalidFormats = 'dest.email.allowInvalidEmailAddress',
  EmailAddress_DgSignEmailMsg = 'dest.email.emailSigningEnabled',
  EmailAddress_DgSignEmailMsg_UserEditable = 'dest.email.emailSigning.isEditable',
  Encrypt_EmailMsg = 'dest.email.emailEncryptionEnabled',
  Encrypt_EmailMsg_UserEditable = 'dest.email.emailEncryption.isEditable',
  SaveTo_SharePoint = 'sharePointEnabled',
  SendTo_Email = 'emailEnabled',
  SaveTo_NetworkFolder = 'folderEnabled',

  // Outgoing Servers
  Outgoing_Servers = 'deviceDescription',
  Outgoing_Servers_ServerName = 'serverAddress',
  Outgoing_Servers_OverwriteOption = 'overwriteOption',
  Outgoing_Servers_OverWriteExistingName = 'overWriteExistingName',
  Outgoing_Servers_Port = 'serverPort',
  Outgoing_Servers_Email = 'serverUsage.email',
  Outgoing_Servers_InternetFax = 'serverUsage.fax',
  Outgoing_Servers_AutomatedEmail = 'serverUsage.automatedEmail',
  Outgoing_Servers_AutoSend = 'serverUsage.autoSend',
  Outgoing_Servers_FileSize = 'fileSize',
  Outgoing_Servers_UseSsl = 'useSsl',
  Outgoing_Server_SplitEmailOption = 'splitEmailOptions',
  Outgoing_Server_SplitEmailOptionCustom = 'fileSize',
  Outgoing_Servers_ValidCert = 'validateServerCertificate',
  Outgoing_Servers_ReqAuth = 'serverRequireAuthentication',
  Outgoing_Server_CredType = 'credential.credentialType',
  Outgoing_Server_User_Name = 'credential.userName',
  Outgoing_Server_Password = 'credential.password',

  // Smart Cloud Print
  Smart_CloudPrint = 'webServices.cloudPrint',
  HPJetAdvantage_MoreApps = 'webServices.hpJetAdvantage',
  AccountCreation = 'webServices.accountCreation',

  // 802.1x Authentication (Dot1x Authentication)
  Dot1x_Authentication_PeapEnabled = 'eth.ethConfig.dot1xAuthConfig.eapPeapEnabled',
  Dot1x_Authentication_TlsEnabled = 'eth.ethConfig.dot1xAuthConfig.eapTlsEnabled',
  Dot1x_Authentication_Username = 'eth.ethConfig.dot1xAuthConfig.userName',
  Dot1x_Authentication_Password = 'eth.ethConfig.dot1xAuthConfig.password',
  Dot1x_Authentication_ServerId = 'eth.ethConfig.dot1xAuthConfig.serverIdentity',
  Dot1x_Authentication_RequireExactMatch = 'eth.ethConfig.dot1xAuthConfig.exactServerIdMatch',
  Dot1x_Authentication_OnAuthenticationFailure = 'eth.ethConfig.dot1xAuthConfig.blockNetworkOnAuthFailure',
  Dot1x_Authentication_Reauthenticate = 'eth.ethConfig.dot1xAuthConfig.reauthenticate',
  //LDAP SignIn Setup
  LDAP_SignIn = 'enabled',
  LDAP_Server_Address = 'ldapSignInConfig.serverAddress',
  LDAP_Port_Number = 'ldapSignInConfig.port',
  LDAP_Use_SSL = 'ldapSignInConfig.secureConnectionEnabled',
  LDAP_Server_Authentication = 'ldapSignInConfig.productCredentialsEnabled',
  LDAP_Use_MFP_Credentials = 'ldapSignInConfig.productCredentialsEnabled',
  LDAP_Bind_Prefix = 'ldapSignInConfig.ldapBindPrefix',
  LDAP_Use_Admin_Credentials = 'ldapSignInConfig.adminstratorCredentialsEnabled',
  LDAP_Admin_DN = 'ldapSignInConfig.ldapAdministratorDN',
  LDAP_Admin_Password = 'ldapSignInConfig.ldapAdministratorPassword',
  LDAP_Bind_And_Search_Root = 'ldapSignInConfig.ldapBindRoots',
  LDAP_match_name_with_attribute = 'ldapSignInConfig.matchLdapNameAttribute',
  LDAP_retrieve_user_email = 'ldapSignInConfig.retrieveLdapEmailAttribute',
  LDAP_retrieve_user_name = 'ldapSignInConfig.retrieveLdapNameAttribute',
  LDAP_retrieve_user_group = 'ldapSignInConfig.retrieveLdapGroupAttribute',
  LDAP_exact_match_on_group_attribute = 'ldapSignInConfig.enableGroupExactMatch',
  // Wifi Direct Configuration
  Wifi_Direct_Enable = 'enabled',
  Wifi_Direct_Suffix = 'ssidSuffix',
  Wifi_Direct_Connection = 'connectionMethod',
  Wifi_Direct_Password = 'passphrase',
  Wifi_Direct_Channel = 'preferredChannel',
  Wifi_Direct_Hide_SSID = 'hideSsid',
  Wifi_Direct_Hide_Password = 'showPassphrase',
  // App Deployment
  App_Deployment_WorkPath_Enablement = 'workpathEnabledState',
  App_Deployment_AppInstallation = 'workpathEnabledState',
  // Restrict Color
  Restrict_Color = 'security.colorAccessControl.restrictColor',
  Restrict_User_Permissions = 'security.colorAccessControl.customColorAccess.usingPermissionSets',
  Restrict_Application = 'security.colorAccessControl.customColorAccess.usingApplicationSettings',
  Default_Permissions = 'security.colorAccessControl.customColorAccess.allowColorAndQuality',
  Non_Default_Applications = 'security.colorAccessControl.applications',
  TrayAdmin_Inputs = 'inputs',
  //Stored Data Pin Protection
  StoredDtaPin_Protection_Limit = 'temporaryJobLimit',
  Stored_Data_Pin_Protection_RequireScanJobStorage = 'requirePrintJobPinProtection',
  //ColorCopyMode
  Color_Copy_Mode = 'src.scan.colorMode',
  //CopyJobBuild
  Copy_JobBuild = 'pipelineOptions.promptForAdditionalPages',
  //WebServices
  HP_WebServicesSettingsName = 'cloudServicesEnabled',
  //Toner Collection Unit
  Marker_Collection_Unit_Full_Action = 'markerCollectionUnitFullAction',
  //Toner Collection Unit
  Cartridge_Protection = 'antiTheftEnabled',
  //Home Screen Application(FutureSmart)
  Default_Home_Screen_Application = 'defaultHomeScreenApplication',
  Emergency_Client_Mode = 'emergencyClientMode',
  HomeScreen_Language = 'languageSelectionEnabled',
  HomeScreen_AlternateKeyboard = 'displayAlternateKeyboards',
  //AccessControl
  Permission_sets = 'rolePermissionMappings.rolePermissionMap.role',
  AccessControlDefault = 'defaultAuthnAgent',
  AlternateSignInMethod = 'alternateSignInMethodsAllowed',
  //Use Requested Tray
  Use_Requested_Tray = 'useRequestedTray',
  //Override A4 Letter
  Override_A4_Letter = 'a4LetterOverrideEnabled',
  Low_Threshold = 'lowThresholdsPerSupply.lowThresholdWithId.threshold',
  //Manual Feed Prompt
  ManualFeed_Prompt = 'manualFeedPrompt',
  //Tray1 Mode Manual feed
  Tray1_Mode_Manual_feed = 'manualFeedEnable',
  System_Location = 'systemLocation',
  System_Contact = 'systemContact',
  Support_Contact = 'supportContact',
  Size_Type_Prompt = 'sizeTypeEnabled',
  //ProxyServers
  ProxyServerEnabled = 'httpProxy.enabled',
  ProxyServerAddress = 'httpProxy.address',
  ProxyServerPort = 'httpProxy.port',
  ProxyServerUser = 'authentication.userId',
  ProxyServerPassword = 'authentication.password',
  //Bootloader Password
  Bootloader_Password = 'passwordSet',
  Bootloader_Current_Password = 'currentPassword',
  Bootloader_Proposed_Password = 'proposedPassword',
  //Email Scan Settings
  Email_Scan_Settings = 'src.scan.contentType',
  // Delay Very Low Message
  Delay_VeryLow_Message = 'veryLowPageDelayPerSupply.pageDelayWithId.numberOfPages'
}

export enum ConstraintsScopeKeyEnum {
  ALLOF = 'allOf',
  ANYOF = 'anyOf',
  ONEOF = 'oneOf',
}

export enum ConstraintsEffectEnum {
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
  NOTIFY = 'NOTIFY',
  SET = 'SET',
}
