import * as React from 'react'
import { AccordionItemInterface } from '@veneer/core/dist/scripts/accordion/types'
import { Accordion } from '@veneer/core'

interface AccordionWrapperProps {
  items: AccordionItemInterface[]
  className?: string
  gutter?: number
  behavior?: 'singleExpand' | 'multiExpand'
  id?: string
  border?: 'none' | 'dropShadow' | 'outlined'
  onExpand?: (
    e: React.UIEvent,
    index: number,
    item: AccordionItemInterface
  ) => void
  onCollapse?: (
    e: React.UIEvent,
    index: number,
    item: AccordionItemInterface
  ) => void
}

const AccordionWrapper: React.FC<AccordionWrapperProps> = ({
  items,
  className,
  behavior,
  id,
  gutter,
  border,
  onExpand,
  onCollapse
}) => {
  const handleExpandCollapse = (
    e: React.UIEvent,
    index: number,
    callback?: (
      e: React.UIEvent,
      index: number,
      item: AccordionItemInterface
    ) => void
  ) => {
    if (callback) {
      callback(e, index, items[index])
    }
  }

  return (
    <div className={className} id={id} key={id}>
      {items.map((item, index) => (
        <div id={item.id} key={item.id}>
          <Accordion
            className={'rightPaddingAccordion nestedAccordion'}
            gutter={gutter}
            items={[item]}
            behavior={behavior}
            border={border}
            onExpand={(e) => handleExpandCollapse(e, index, onExpand)}
            onCollapse={(e) => handleExpandCollapse(e, index, onCollapse)}
          />
        </div>
      ))}
    </div>
  )
}

export default AccordionWrapper
