import React, { useContext, useEffect } from 'react'
import policiesContext from 'context/policies/policiesContext'
import PolicySettings from 'components/policies/settings'

const SimplePolicyPreview = ({ policyId }) => {
  const { getPolicy, selectedPolicy, setSelectedPolicy } =
    useContext(policiesContext)

  useEffect(() => {
    if (policyId) {
      getPolicy(policyId, () => {
        setSelectedPolicy({ attributes: [] })
      })
    }
  }, [policyId])

  return (
    <PolicySettings
      attributes={selectedPolicy?.attributes}
      preview={true}
      outlined={true}
    />
  )
}

export default SimplePolicyPreview
