import LocalizeHelper from '../LocalizeHelper';

export const DelayVeryLowMessageHelper = (id, propsData) => {

  const { getDeviceCommonLocString } = LocalizeHelper();

  const idMapping = {
    1: ["black_very_low_message_threshold"],
    2: ["cyan_very_low_message_threshold"],
    3: ["magenta_very_low_message_threshold"],
    4: ["yellow_very_low_message_threshold"],
    5: ["black_drum_very_low_message_threshold"],
    6: ["cyan_drum_very_low_message_threshold"],
    7: ["magenta_drum_very_low_message_threshold"],
    8: ["yellow_drum_very_low_message_threshold"],
  };

  const DelayVeryLowMessageValue = (id, inputArray) => {
    let result = [];
    inputArray?.forEach(item => {
      const targetString = idMapping[item.id];

      if (targetString !== undefined) {
        const value = item.numberOfPages;
        result.push({'threshold_name': getDeviceCommonLocString(id, targetString), 'page_threshold': value});
      }
    });

    return result;
  };

  const tableData = DelayVeryLowMessageValue(id, propsData);

  return { DelayVeryLowMessageData: tableData };
};
