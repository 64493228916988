import { t, toTitleCase } from 'src/utils/commonMethods';
import { ComplianceType } from 'src/utils/constants';

export const getGroupsText = (groups) => {
  if (groups.length === 0) {
    return '--';
  }
  return groups?.map((group) => toTitleCase(group.label)).join(', ');
};

export const getConnectionStateText = (value) => {
  switch (value) {
    case 'online':
      return t('table.online');
    case 'offline':
    default:
      return t('table.offline');
  }
};

export const getAppNames = (apps) => {
  const appNames = apps.map((app) => app.title);

  if (!apps || appNames.length == 0) {
    return '--';
  }
  if (appNames.length == 1) {
    return appNames[0];
  }
  return `${appNames[0]} & more ${appNames.length - 1}`;
};

export const getAssessmentText = (assessment) => {
  switch (assessment) {
    case 'highRisk':
      return t('table.high_risk');
    case 'mediumRisk':
      return t('table.medium_risk');
    case 'lowRisk':
      return t('table.low_risk');
    case 'passed':
      return t('table.passed');
    case 'notAssessed':
    default:
      return t('table.not_assessed');
  }
};

export const getStatusText = (value) => {
  switch (value) {
    case 'UNKNOWN':
      return t('table.unknown');
    case 'ERROR':
      return t('table.error');
    case 'WARNING':
      return t('table.warning');
    case 'READY':
      return t('table.ready');
  }
};

export const getPolicyName = (policies) => {
  if (!policies) return ComplianceType.NO_DATA;
  const policyName = (name) => name || t('table.deviceSpecific');
  return policies.length > 1
    ? t('table.more', {
        name: policyName(policies[0].name),
        count: policies.length - 1 + '',
      })
    : policies.length
    ? policyName(policies[0].name)
    : ComplianceType.NO_DATA;
};

export const getComplianceText = (status, policies) => {
  switch (status) {
    case ComplianceType.Compliant:
      return t('table.complianceTypes.compliant');
    case ComplianceType.NonCompliant:
      return t('table.complianceTypes.noncompliant', {
        count: policies.filter((x) => x.status === ComplianceType.Compliant).length,
        total: policies.length,
      });
    default:
      // ComplianceType.Unknown
      return t('table.complianceTypes.unknown');
  }
};

export const capitalizeFirstLetter = (str) => {
  return str.replace(/^./, str[0].toUpperCase());
};
