import { Table } from '@veneer/core';
import React from 'react';
import { SubfeatureIdEnum, SleepScheduleWeeklyTableIds, SleepScheduleHolidayTableIds, ApplicationIds } from '../../../../../config/SubFeatureEnums';
import useTableBody from '../../../../../hooks/table/useTableBody';
import useTableHead from '../../../../../hooks/table/useTableHead';
import OutGoingServerTable from './OutGoingServerTable';
import AppDeploymentTable from '../../../../CustomizableComponents/AppsDeployment/AppDeploymentTable';
import { StyledTable } from '../Styles';

const  TableTemplate = ({ id, subFeatureId, value , wex , deviceConstraints  }) => {
  const { tableHead } = useTableHead(subFeatureId);
  const { tableBody } = useTableBody(id , subFeatureId, tableHead, value , deviceConstraints);

  const getPreferences = (subFeatureId) => {
    switch (subFeatureId) {
      case SubfeatureIdEnum.Sleep_Schedule_Holiday:
        return {
          width: [
            { columnId: SleepScheduleHolidayTableIds[0], width: 24.578 },
            { columnId: SleepScheduleHolidayTableIds[1], width: 24.819 },
            { columnId: SleepScheduleHolidayTableIds[2], width: 50.603 },
          ],
        };
      case SubfeatureIdEnum.Sleep_Schedule_Weekly:
        return {
          width: [
            { columnId: SleepScheduleWeeklyTableIds[0], width: 24.578 },
            { columnId: SleepScheduleWeeklyTableIds[1], width: 24.819 },
            { columnId: SleepScheduleWeeklyTableIds[2], width: 19.277 },
            { columnId: SleepScheduleWeeklyTableIds[3], width: 31.326 },
          ],
        };
        case SubfeatureIdEnum.Non_Default_Applications:
        return {
          width: [
            { columnId: ApplicationIds[0], width: 20},
            { columnId: ApplicationIds[1], width: 60},
          ],
        };
      default:
        return {};
    }
  };

  let component;
  switch (subFeatureId) {
    case SubfeatureIdEnum.Outgoing_Server_List_Email:
      component = <OutGoingServerTable id={id} subFeatureId={subFeatureId} value={value} />;
      break;
    case SubfeatureIdEnum.App_Deployment_AppInstallation:
      component = <AppDeploymentTable isEditView = {false} device_settings={value}  />;
      break;
    default:
      component = (
        <StyledTable
          data-testid="my-SleepScheduleWeeklyTable"
          columns={tableHead ? tableHead : []}
          data={tableBody ? tableBody : []}
          wex={wex}
          preferences={getPreferences(subFeatureId)}
        />
      );
  }

  return component;
};

export default TableTemplate;
