import { refined, refinedArray, refinedDate } from 'src/utils/commonMethods';

const refinedCompliances = (data) => {
  return (
    data.items?.map((item) => ({
      deviceId: refined(item.deviceId),
      model: refined(item.model),
      serialNumber: refined(item.serialNumber),
      status: refined(item.status),
      lastRunAt: item.policies.length > 0 ? refinedDate(item.lastRunAt) : '--',
      policies: refinedArray(item.policies),
    })) ?? {
      deviceId: '--',
      model: '--',
      serialNumber: '--',
      status: '--',
      lastRunAt: '--',
      policies: [],
    }
  );
};

export { refinedCompliances };
