import configContext from 'context/config/configContext'
import { ObjectDropboxDescription } from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import LabelSelect from 'common/controls/labelSelect'
import settingsContext from 'context/settings/settingsContext'
import React, { useContext, useEffect, useState } from 'react'
import HelpButton from '../../HelpButton'

const ObjectDropboxControl = (props) => {
  const description: ObjectDropboxDescription = props.description
  const {
    id,
    attributes,
    localizationPath,
    data: { deviceSettings }
  } = props
  const { attribute, help, label, items } = description

  const { tt } = useContext(configContext)
  const {
    isEnabled,
    addDisabled,
    removeDisabled,
    displayAllErrors,
    addError,
    removeError
  } = useContext(settingsContext)

  const getLocalized = (key: string, params?): string =>
    tt(localizationPath, key, params)

  const enabled = isEnabled(attribute)
  const [value, setValue] = useState([
    PoliciesHelper.getData(description, deviceSettings).value
  ])
  const [error, setError] = useState(false)
  const [displayError, setDisplayError] = useState(displayAllErrors)
  const showError = displayError || displayAllErrors

  useEffect(() => {
    const err = enabled && !value.toString()
    setError(err && showError)
    err ? addError(id, attribute, showError) : removeError(id, attribute)
  }, [enabled, value, showError])

  useEffect(
    () => setValue([PoliciesHelper.getData(description, deviceSettings).value]),
    [deviceSettings]
  )

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const val = PoliciesHelper.getData(description, deviceSettings)
    if (attributes && val.value === value.toString()) {
      PoliciesHelper.update(
        description,
        val,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  const retrieveSelectedValue = (inputValue) =>
    items.find((item) => item.value === inputValue)

  const onChange = (v) => {
    const val = v.toString()
    setDisplayError(true)
    setValue([val])

    const selectedValue = retrieveSelectedValue(val)
    if (selectedValue) {
      const { ...finalValue } = selectedValue
      delete finalValue.label // remove label from final value
      const settings = [...deviceSettings]
      PoliciesHelper.setData(description, settings, { ...finalValue })
      props.onAttributeChange({ ...props.data, deviceSettings: settings })
    }
  }

  const options = items.map((v) => {
    return { value: v.value, label: getLocalized(v.label) }
  })

  return (
    <LabelSelect
      label={getLocalized(label)}
      disabled={!enabled}
      helpButton={
        help && (
          <HelpButton enabled={enabled} description={getLocalized(help)} />
        )
      }
      className={'maxTextWidth'}
      options={options}
      onChange={({ value: v }) => onChange(v)}
      placeholder={getLocalized('common.select-option')}
      id={attribute}
      value={value}
      error={error}
      helperText={error ? getLocalized('common.errors.not-selected') : null}
      showSearch={true}
    />
  )
}

export default ObjectDropboxControl
