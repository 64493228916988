export enum DeviceCacheCdmDataEnum {
  // System Configuration //
  AssetNumber = 'cdmData.assetNumber',
  CompanyName = 'cdmData.companyName',
  CompanyContact = 'cdmData.companyContact',
  DeviceLocation = 'cdmData.deviceLocation',
  DeviceDescription = 'cdmData.deviceDescription',

  // Outgoing Servers
  OutgoingServers = 'cdmData.outgoingServers',
  OutgoingServersServerAddress = 'cdmData.serverAddress',
  OutgoingServersPort = 'cdmData.serverPort',
  OutgoingServersServerUsageEmail = 'cdmData.servers.serverUsage.email',
  OutgoingServersServerUsageInternetFax = 'cdmData.servers.serverUsage.fax',
  OutgoingServersServerUsageAutomatedEmail = 'cdmData.servers.serverUsage.automatedEmail',
  OutgoingServersServerUsageAutoSend = 'cdmData.servers.serverUsage.autoSend',

  // Sleep Settings
  Sleep_Settings_Sleep_Mode_Auto_Off_Timer = 'cdmData.sleepAutoOffTimerEnabled',
  Sleep_Settings_Sleep_Mode = 'cdmData.sleepModeTimeout',
  Sleep_Settings_Auto_On_Events = 'cdmData.autoOnEvents',
  Sleep_Settings_Auto_Off_After_Sleep = 'cdmData.autoOffTimeout',

  // SNMPV1/V2 and V3 //
  SNMPV1V2ConfigContextName = 'cdmData.snmpV1V2Config.contextName',
  SNMPV1V2ConfigAccessOption = 'cdmData.snmpV1V2Config.accessOption',
  SNMPV1V2ReadOnlyCommunityNameSet = 'cdmData.snmpV1V2Config.readOnlyCommunityNameSet',
  SNMPV1V2ConfigReadOnlyPublicAllowed = 'cdmData.snmpV1V2Config.readOnlyPublicAllowed',
  SNMPV1V2ConfigWriteOnlyCommunityNameSet = 'cdmData.snmpV1V2Config.writeOnlyCommunityNameSet',
  SNMPV1V2ConfigEnabled = 'cdmData.snmpV1V2Config.enabled',
  SNMPV3ConfigAuthenticationAlgorithm = 'cdmData.snmpV3Config.authenticationAlgorithm',
  SNMPV3ConfigMinPasswordLength = 'cdmData.snmpV3Config.minPasswordLength',
  SNMPV3ConfigPrivacyAlgorithm = 'cdmData.snmpV3Config.privacyAlgorithm',
  SNMPV3ConfigkeyType = 'cdmData.snmpV3Config.keyType',
  SNMPV3ConfigAccountLockoutMaxAttempt = 'cdmData.snmpV3Config.accountLockout.maximumAttempts',
  SNMPV3ConfigLockoutInterval = 'cdmData.snmpV3Config.accountLockout.lockoutInterval',
  SNMPV3ConfigResetLockoutCounterInterval = 'cdmData.snmpV3Config.accountLockout.resetLockoutCounterInterval',
  SNMPV3ConfigAccountLockoutEnabled = 'cdmData.snmpV3Config.accountLockout.enabled',
  SNMPV3Configenabled = 'cdmData.snmpV3Config.enabled',
  SNMPV3ConfigPasswordComplexityEnabled = 'cdmData.snmpV3Config.passwordComplexityEnabled',

  // DSS Config //
  DSSConfigMinPasswordLength = 'cdmData.minPasswordLength',
  DSSConfigPasswordConfigured = 'cdmData.passwordConfigured',
  DSSConfigAccountLockoutMaximumAttempts = 'cdmData.accountLockout.maximumAttempts',
  DSSConfigLockoutInterval = 'cdmData.accountLockout.lockoutInterval',
  DSSConfigResetLockoutCounterInterval = 'cdmData.accountLockout.resetLockoutCounterInterval',
  DSSConfigEnabled = 'cdmData.accountLockout.enabled',
  DSSConfigPasswordComplexityEnabled = 'cdmData.passwordComplexityEnabled',

  // Account Policy//
  AccountPolicyMinPasswordLength = 'cdmData.minPasswordLength',
  AccountPolicyMinPasswordLengthEnabled = 'cdmData.minPasswordLengthEnabled',
  AccountPolicyAccountLockoutMaximumAttempts = 'cdmData.accountLockout.maximumAttempts',
  AccountPolicyConfigLockoutInterval = 'cdmData.accountLockout.lockoutInterval',
  AccountPolicyResetLockoutCounterInterval = 'cdmData.accountLockout.resetLockoutCounterInterval',
  AccountPolicyAccountLockoutEnabled = 'cdmData.accountLockout.enabled',
  AccountPolicyPasswordComplexityEnabled = 'cdmData.passwordComplexityEnabled',

  // AdapterConfig USB //
  AdapterConfigUSBEnabled = 'cdmData.enabled',

  // Systemconfig //
  SystemconfigCsrfPreventionEnabled = 'cdmData.csrfPreventionEnabled',
  SystemconfigHttpsRedirectionEnabled = 'cdmData.httpsRedirectionEnabled',
  SystemconfigCORSEnabled = 'cdmData.corsEnabled',
  SystemconfigCORSTrustedSite = 'cdmData.trustedSites',
  // FirmwareUpdate Configuration //
  FWUpdateConfigsha1ValidationEnabled = 'cdmData.sha1ValidationEnabled',
  FWUpdateConfigpjlUpdateEnabled = 'cdmData.pjlUpdateEnabled',

  // FaxReceive //
  FaxReceiveuseHolidaySleepScheduleEnabled = 'cdmData.useHolidaySleepScheduleEnabled',
  FaxReceivefitToPageEnabled = 'cdmData.fitToPageEnabled',
  FaxReceivestampReceivedFaxesEnabled = 'cdmData.stampReceivedFaxesEnabled',
  FaxReceiveMethod = 'cdmData.faxReceiveMethod',

  //FaxSend//
  IppFaxEnabled = 'cdmData.ippFaxEnabled',

  // Devicefirmware //
  Devicefirmware = 'cdmData.version',

  // EWSConfiguration //
  EWSConfigSelectedLanguage = 'cdmData.selectedLanguage',
  EWSConfigDisplayPrintPageOnInformationTab = 'cdmData.displayPrintPageOnInformationTab',
  EWSConfigDisplayJobLogOnInformationTab = 'cdmData.displayJobLogOnInformationTab',
  EWSConfigEwsEnabled = 'cdmData.ewsEnabled',
  EWSConfigSessionTimeout = 'cdmData.sessionTimeout',
  EWSConfigInformationTabAccess = 'cdmData.informationTabAccess',
  EWSConfigShowSupportLinks = 'cdmData.showSupportLinks',

  // LDAPConfiguration //
  LDAP_SignIn = 'cdmData.enabled',
  LDAP_ServerAddress = 'cdmData.ldapSignInConfig.serverAddress',
  LDAP_PortNumber = 'cdmData.ldapSignInConfig.port',
  LDAP_UseSSL = 'cdmData.ldapSignInConfig.secureConnectionEnabled',
  LDAP_Use_MFP_Credentials = 'cdmData.ldapSignInConfig.productCredentialsEnabled',
  LDAP_Bind_Prefix = 'cdmData.ldapSignInConfig.ldapBindPrefix',
  LDAP_Use_Admin_Credentials = 'cdmData.ldapSignInConfig.productCredentialsEnabled',
  LDAP_Admin_DN = 'cdmData.ldapSignInConfig.ldapAdministratorDN',
  LDAP_Admin_Password = 'cdmData.ldapSignInConfig.ldapAdministratorPassword',
  LDAP_BindAndSearchRoot = 'cdmData.ldapSignInConfig.ldapBindRoots',
  LDAP_MatchNameWithAttribute = 'cdmData.ldapSignInConfig.matchLdapNameAttribute',
  LDAP_RetrieveUserEmail = 'cdmData.ldapSignInConfig.retrieveLdapEmailAttribute',
  LDAP_RetrieveUserName = 'cdmData.ldapSignInConfig.retrieveLdapNameAttribute',
  LDAP_RetrieveUserGroup = 'cdmData.ldapSignInConfig.retrieveLdapGroupAttribute',
  LDAP_ExactMatchOnGroupAttribute = 'cdmData.ldapSignInConfig.enableGroupExactMatch',

  // Printconfig //
  PrintconfigQuietModeEnabled = 'cdmData.quietModeEnabled',
  PrintconfigPsFileSystemAccessEnabled = 'cdmData.psFileSystemAccessEnabled',
  PrintconfigPJLDeviceAccessCommandsEnabled = 'cdmData.pjlDeviceAccessCommandsEnabled',
  PrintconfigAutoJamRecovery = 'cdmData.autoJamRecovery',
  PrintconfigPJLPasswordConfigured = 'cdmData.pjlPasswordConfigured',
  PrintconfigPJLFileSystemAccessEnabled = 'cdmData.pjlFileSystemAccessEnabled',

  // DiscoveryServices //
  WSDiscoveryEnabled = 'cdmData.wsDiscovery.enabled',
  SLPEnabled = 'cdmData.slp.enabled',
  BonjourDomainName = 'cdmData.bonjour.domainName',
  BonjourServiceName = 'cdmData.bonjour.serviceName',
  BonjourHighestPriorityPrintService = 'cdmData.bonjour.highestPriorityPrintService',
  BonjourEnabled = 'cdmData.bonjour.enabled',

  // NameResolverServices //
  WinsPort = 'cdmData.wins.winsPort',
  WinsSecondaryServer = 'cdmData.wins.secondaryServer',
  WinsPrimaryServer = 'cdmData.wins.primaryServer',
  WinsEnabled = 'cdmData.wins.enabled',
  LlmnrEnabled = 'cdmData.llmnr.enabled',

  // Adminconfig //
  AdminConfigPasswordSet = 'cdmData.passwordSet',
  AdminConfigconfiguredByUser = 'cdmData.configuredByUser',

  // USBHostConfiguration //
  USBHostConfigurationPrintFromUsbEnabled = 'cdmData.printFromUsbEnabled',
  USBHostConfigurationScanToUsbEnabled = 'cdmData.scanToUsbEnabled',
  USBHostConfigurationPlugAndPlayEnabled = 'cdmData.plugAndPlayEnabled',

  // ControlPanelAccess //
  ControlPanelAccessLevel = 'cdmData.accessLevel',

  //serviceUserConfig
  ServiceUserConfigPasswordConfiguredByUser = 'cdmData.passwordConfiguredByUser',

  // Deviceadapterconfig eth1 //
  Eth1DeviceadapterconfigEth1MACAddress = 'cdmData.eth1.macAddress',
  Eth1DeviceadapterconfigIPV4Address = 'cdmData.eth1.ipv4.address.requestedConfigMethod',
  Eth1IPV4subnet = 'cdmData.eth1.ipv4.address.subnet',
  Eth1IPV4DhcpLeaseTime = 'cdmData.eth1.ipv4.address.dhcpLeaseTime',
  Eth1IPV4ActiveConfigMethod = 'cdmData.eth1.ipv4.address.activeConfigMethod',
  Eth1IPV4Ip = 'cdmData.eth1.ipv4.address.ip',
  Eth1IPV4DhcpFqdnRfc4702Compliance = 'cdmData.eth1.ipv4.address.dhcpFqdnRfc4702Compliance',
  Eth1IPV4ConfiguredBy = 'cdmData.eth1.ipv4.address.configuredBy',
  Eth1IPV4Gateway = 'cdmData.eth1.ipv4.address.gateway',
  Eth1IPV4enabled = 'cdmData.eth1.ipv4.enabled',
  Eth1IPV4MulticastEnabled = 'cdmData.eth1.ipv4.multicastEnabled',
  Eth1IPV4DnsServerSecondary = 'cdmData.eth1.ipv4.dnsServer.secondary.address',
  Eth1IPV4DnsServerPrimary = 'cdmData.eth1.ipv4.dnsServer.primary.address',
  Eth1connectionState = 'cdmData.eth1.connectionState',
  Eth1DomainNameV6 = 'cdmData.eth1.identity.domainNameV6.name',
  Eth1Hostname = 'cdmData.eth1.identity.hostname.name',
  Eth1DomainName = 'cdmData.eth1.identity.domainName.name',
  Eth1IPV6PreFixLength = 'cdmData.eth1.ipv6.addresses.prefixLength',
  Eth1IPV6Address = 'cdmData.eth1.ipv6.addresses.address',
  Eth1IPV6AddressType = 'cdmData.eth1.ipv6.addresses.addressType',
  Eth1IPV6Stateless = 'cdmData.eth1.ipv6.stateless',
  Eth1IPV6ManualAddPrefix = 'cdmData.eth1.ipv6.manual.address.prefixLength',
  Eth1IPV6ManualAddress = 'cdmData.eth1.ipv6.manual.address.address',
  Eth1IPV6ManualAddressType = 'cdmData.eth1.ipv6.manual.address.addressType',
  Eth1IPV6ManualEnabled = 'cdmData.eth1.ipv6.manual.enabled',
  Eth1IPV6SecondaryAddress = 'cdmData.eth1.ipv6.dnsServer.secondary.address',
  Eth1IPConfigPrecedence = 'cdmData.eth1.ipConfigPrecedence',
  Eth1IPV6PrimaryAddress = 'cdmData.eth1.ipv6.dnsServer.primary.address',
  Eth1IPV6Dhcpv6 = 'cdmData.eth1.ipv6.dhcpv6Policy',
  Eth1IPV6Enabled = 'cdmData.eth1.ipv6.enabled',
  Eth1adapterName = 'cdmData.eth1.adapterName',
  Eth1version = 'cdmData.eth1.version',
  Eth1adapterType = 'cdmData.eth1.adapterType',
  Eth1DnsSuffixList = 'cdmData.eth1.dnsSuffixList.suffixes.name',
  Eth1LinkConfigConfig = 'cdmData.eth1.ethConfig.linkConfig',
  Eth1Dot1xEnabled = 'cdmData.eth1.ethConfig.dot1xEnabled',
  Eth1LinkSpeed = 'cdmData.eth1.ethConfig.linkSpeed',
  Eth1Dot1xAuthConfig = 'cdmData.eth1.ethConfig.dot1xAuthConfig.eapPeapEnabled',
  Eth1Password = 'cdmData.eth1.ethConfig.password',
  Eth1EapLeapEnabled = 'cdmData.eth1.ethConfig.eapLeapEnabled',
  Eth1Reauthenticate = 'cdmData.eth1.ethConfig.reauthenticate',
  Eth1ExactServerIdMatch = 'cdmData.eth1.ethConfig.exactServerIdMatch',
  Eth1EapTlsEnabled = 'cdmData.eth1.ethConfig.eapTlsEnabled',
  Eth1UserName = 'cdmData.eth1.ethConfig.userName',

  // Deviceadapterconfig eth0 //
  Eth0DeviceadapterconfigEth0MACAddress = 'cdmData.eth0.macAddress',
  Eth0DeviceadapterconfigIPV4Address = 'cdmData.eth0.ipv4.address.requestedConfigMethod',
  Eth0IPV4subnet = 'cdmData.eth0.ipv4.address.subnet',
  Eth0IPV4DhcpLeaseTime = 'cdmData.eth0.ipv4.address.dhcpLeaseTime',
  Eth0IPV4ActiveConfigMethod = 'cdmData.eth0.ipv4.address.activeConfigMethod',
  Eth0IPV4Ip = 'cdmData.eth0.ipv4.address.ip',
  Eth0IPV4DhcpFqdnRfc4702Compliance = 'cdmData.eth0.ipv4.address.dhcpFqdnRfc4702Compliance',
  Eth0IPV4ConfiguredBy = 'cdmData.eth0.ipv4.address.configuredBy',
  Eth0IPV4Gateway = 'cdmData.eth0.ipv4.address.gateway',
  Eth0IPV4enabled = 'cdmData.eth0.ipv4.enabled',
  Eth0IPV4MulticastEnabled = 'cdmData.eth0.ipv4.multicastEnabled',
  Eth0IPV4DnsServerSecondary = 'cdmData.eth0.ipv4.dnsServer.secondary.address',
  Eth0IPV4DnsServerPrimary = 'cdmData.eth0.ipv4.dnsServer.primary.address',
  Eth0connectionState = 'cdmData.eth0.connectionState',
  Eth0DomainNameV6 = 'cdmData.eth0.identity.domainNameV6.name',
  Eth0Hostname = 'cdmData.eth0.identity.hostname.name',
  Eth0DomainName = 'cdmData.eth0.identity.domainName.name',
  Eth0IPV4DnsServerDomainName = 'cdmData.eth0.identity.domainName.name',
  Eth0IPV6PreFixLength = 'cdmData.eth0.ipv6.addresses.prefixLength',
  Eth0IPV6Address = 'cdmData.eth0.ipv6.addresses.address',
  Eth0IPV6AddressType = 'cdmData.eth0.ipv6.addresses.addressType',
  Eth0IPV6Stateless = 'cdmData.eth0.ipv6.stateless',
  Eth0IPV6ManualAddPrefix = 'cdmData.eth0.ipv6.manual.address.prefixLength',
  Eth0IPV6ManualAddress = 'cdmData.eth0.ipv6.manual.address.address',
  Eth0IPV6ManualAddressType = 'cdmData.eth0.ipv6.manual.address.addressType',
  Eth0IPV6ManualEnabled = 'cdmData.eth0.ipv6.manual.enabled',
  Eth0IPV6SecondaryDnsServer = 'cdmData.eth0.ipv6.dnsServer.secondary.address',
  Eth0IPConfigPrecedence = 'cdmData.ipConfigPrecedence',
  Eth0IPV6PrimaryDnsServer = 'cdmData.eth0.ipv6.dnsServer.primary.address',
  Eth0IPV6Dhcpv6 = 'cdmData.eth0.ipv6.dhcpv6Policy',
  Eth0IPV6Enabled = 'cdmData.eth0.ipv6.enabled',
  Eth0adapterName = 'cdmData.eth0.adapterName',
  Eth0version = 'cdmData.eth0.version',
  Eth0adapterType = 'cdmData.eth0.adapterType',
  Eth0DnsSuffixList = 'cdmData.eth0.dnsSuffixList.suffixes.name',
  Eth0LinkConfigConfig = 'cdmData.eth0.ethConfig.linkConfig',
  Eth0Dot1xEnabled = 'cdmData.eth0.ethConfig.dot1xEnabled',
  Eth0LinkSpeed = 'cdmData.eth0.ethConfig.linkSpeed',
  Eth0Dot1xAuthConfig = 'cdmData.eth0.ethConfig.dot1xAuthConfig.eapPeapEnabled',
  Eth0Password = 'cdmData.eth0.ethConfig.password',
  Eth0EapLeapEnabled = 'cdmData.eth0.ethConfig.eapLeapEnabled',
  Eth0Reauthenticate = 'cdmData.eth0.ethConfig.reauthenticate',
  Eth0ExactServerIdMatch = 'cdmData.eth0.ethConfig.exactServerIdMatch',
  Eth0EapTlsEnabled = 'cdmData.eth0.ethConfig.eapTlsEnabled',
  Eth0UserName = 'cdmData.eth0.ethConfig.userName',

  // Deviceadapterconfig Wifi0 //

  Wifi0Deviceadapterconfigwifi0MACAddress = 'cdmData.wifi0.macAddress',
  Wifi0DeviceadapterconfigIPV4Address = 'cdmData.wifi0.ipv4.address.requestedConfigMethod',
  Wifi0IPV4subnet = 'cdmData.wifi0.ipv4.address.subnet',
  Wifi0IPV4DhcpLeaseTime = 'cdmData.wifi0.ipv4.address.dhcpLeaseTime',
  Wifi0IPV4ActiveConfigMethod = 'cdmData.wifi0.ipv4.address.activeConfigMethod',
  Wifi0IPV4Ip = 'cdmData.wifi0.ipv4.address.ip',
  Wifi0IPV4DhcpFqdnRfc4702Compliance = 'cdmData.wifi0.ipv4.address.dhcpFqdnRfc4702Compliance',
  Wifi0IPV4ConfiguredBy = 'cdmData.wifi0.ipv4.address.configuredBy',
  Wifi0IPV4Gateway = 'cdmData.wifi0.ipv4.address.gateway',
  Wifi0IPV4enabled = 'cdmData.wifi0.ipv4.enabled',
  Wifi0IPV4MulticastEnabled = 'cdmData.wifi0.ipv4.multicastEnabled',
  Wifi0IPV4DnsServerSecondary = 'cdmData.wifi0.ipv4.dnsServer.secondary.address',
  Wifi0IPV4DnsServerPrimary = 'cdmData.wifi0.ipv4.dnsServer.primary.address',
  Wifi0connectionState = 'cdmData.wifi0.connectionState',
  Wifi0DomainNameV6 = 'cdmData.wifi0.identity.domainNameV6.name',
  Wifi0Hostname = 'cdmData.wifi0.identity.hostname.name',
  Wifi0DomainName = 'cdmData.wifi0.identity.domainName.name',
  Wifi0IPV6PreFixLength = 'cdmData.wifi0.ipv6.addresses.prefixLength',
  Wifi0IPV6Address = 'cdmData.wifi0.ipv6.addresses.address',
  Wifi0IPV6AddressType = 'cdmData.wifi0.ipv6.addresses.addressType',
  Wifi0IPV6Stateless = 'cdmData.wifi0.ipv6.stateless',
  Wifi0IPV6ManualAddPrefix = 'cdmData.wifi0.ipv6.manual.address.prefixLength',
  Wifi0IPV6ManualAddress = 'cdmData.wifi0.ipv6.manual.address.address',
  Wifi0IPV6ManualAddressType = 'cdmData.wifi0.ipv6.manual.address.addressType',
  Wifi0IPV6ManualEnabled = 'cdmData.wifi0.ipv6.manual.enabled',
  Wifi0IPV6SecondaryDnsServer = 'cdmData.wifi0.ipv6.dnsServer.secondary.address',
  Wifi0IPConfigPrecedence = 'cdmData.wifi0.ipConfigPrecedence',
  Wifi0IPV6PrimaryDnsServer = 'cdmData.wifi0.ipv6.dnsServer.primary.address',
  Wifi0IPV6Dhcpv6 = 'cdmData.wifi0.ipv6.dhcpv6Policy',
  Wifi0IPV6Enabled = 'cdmData.wifi0.ipv6.enabled',
  Wifi0adapterName = 'cdmData.wifi0.adapterName',
  Wifi0version = 'cdmData.wifi0.version',
  Wifi0adapterType = 'cdmData.wifi0.adapterType',
  Wifi0DnsSuffixList = 'cdmData.wifi0.dnsSuffixList.suffixes.name',
  Wifi0LinkConfigConfig = 'cdmData.wifi0.ethConfig.linkConfig',
  Wifi0Dot1xEnabled = 'cdmData.wifi0.ethConfig.dot1xEnabled',
  Wifi0LinkSpeed = 'cdmData.wifi0.ethConfig.linkSpeed',
  Wifi0Dot1xAuthConfig = 'cdmData.wifi0.ethConfig.dot1xAuthConfig.eapPeapEnabled',
  Wifi0Password = 'cdmData.wifi0.ethConfig.password',
  Wifi0EapLeapEnabled = 'cdmData.wifi0.ethConfig.eapLeapEnabled',
  Wifi0Reauthenticate = 'cdmData.wifi0.ethConfig.reauthenticate',
  Wifi0ExactServerIdMatch = 'cdmData.wifi0.ethConfig.exactServerIdMatch',
  Wifi0EapTlsEnabled = 'cdmData.wifi0.ethConfig.eapTlsEnabled',
  Wifi0UserName = 'cdmData.wifi0.ethConfig.userName',

  //Clock Configuration
  ClockLocalTime = 'cdmData.localTime',
  ClockNtpSyncFrequency = 'cdmData.ntpSyncFrequency',
  ClockDstEnabled = 'cdmData.dstEnabled',
  ClockSystemTimeSync = 'cdmData.systemTimeSync',
  ClockDateFormat = 'cdmData.dateFormat',
  ClockTimeFormat = 'cdmData.timeFormat',
  ClockNtpLocalPortNumber = 'cdmData.ntpLocalPortNumber',
  ClockTimeZone = 'cdmData.timeZone',
  ClockTimeZoneDescription = 'cdmData.timeZoneDescription',
  ClockNtpServer = 'cdmData.ntpServer',

  //Power Configuration
  PowerConfigSleepTimeout = 'cdmData.sleepTimeout',

  //Print Job Ticket
  PrintJobDuplexBinding = 'cdmData.dest.print.duplexBinding',
  PrintJobMediaSize = 'cdmData.dest.print.mediaSize',
  PrintJobMediaType = 'cdmData.dest.print.mediaType',

  //Network Scan Service
  AirPrintScan = 'cdmData.scanServices.eSCL.eSCL',
  AirPrintSecureScan = 'cdmData.scanServices.eSCL.eSCLSecure',

  //ConfigPrivate_Supplies
  VeryLowMessage = 'cdmData.veryLowPageDelayEnabled',
  SupplyPolicy = 'cdmData.supplyPolicy',
  MarkerCollectionUnitFullAction = 'cdmData.markerCollectionUnitFullAction',
  CartridgeProtection = 'cdmData.antiTheftEnabled',
  LowThreshold = 'cdmData.lowThresholdsPerSupply',
  DelayVeryLowMessage = 'cdmData.veryLowPageDelayPerSupply',

  //Control Panel

  CurrentLanguage = 'cdmData.currentLanguage',
  CurrentKeyboardLayout = 'cdmData.currentKeyboardLayout',

  //WorkPath
  WorkPathEnablement = 'cdmData.workPathEnablement',
  SignInOnce = 'cdmData.signInOnce',

  //BlueTooth Configuration
  RequestedBeaconingEnabled = 'cdmData.requestedBeaconingEnabled',
  CurrentBeaconingEnabled = 'cdmData.currentBeaconingEnabled',

  //EmailAddressAndMessage Setting
  EmailAddressRestrictionEnabled = 'cdmData.dest.email.addressFieldRestrictionsEnabled',
  EmailAddressInvalidFormats = 'cdmData.dest.email.allowInvalidEmailAddress',
  EmailAddressDglSign = 'cdmData.dest.email.emailSigningEnabled',
  EmailAddressDglSignUserEditable = 'cdmData.dest.email.emailSigning.isEditable',
  EmailAddressEncrypt = 'cdmData.dest.email.emailEncryptionEnabled',
  EmailAddressEncryptUserEditable = 'cdmData.dest.email.emailEncryption.isEditable',
  EmailMessageSettingTable = 'cdmData.dest.email',

  //Scan To Destination
  SaveToSharePoint = 'cdmData.sharePointEnabled',
  SendToEmail = 'cdmData.emailEnabled',
  SaveToNetworkFolder = 'cdmData.folderEnabled',
  //printServices
  FTPPrintEnabled = 'cdmData.ftpPrint.enabled',
//EWSConfig
  EWSConfig = 'cdmData.ewsEnabled',
  // Mock
  MockValueOutgoingServer = 'cdmData.servers',
  MockValueCors = 'cdmData.corsdata',
  CdmData = 'cdmData',
  ColorCopyMode = 'cdmData.src.scan.colorMode',
  //CopyJobBuild
  CopyJobBuild = 'cdmData.pipelineOptions.promptForAdditionalPages',

  // FleetProxyLegacyAttributes
  HPJetAdvantage = 'cdmData.webServices.hpJetAdvantage',
  AccountCreation = 'cdmData.webServices.accountCreation',
  CloudPrint = 'cdmData.webServices.cloudPrint',

  // 802.1x Authentication (Dot1x Authentication)
  Dot1x_Authentication_PeapEnabled = 'cdmData.ethConfig.dot1xAuthConfig.eapPeapEnabled',
  Dot1x_Authentication_TlsEnabled = 'cdmData.ethConfig.dot1xAuthConfig.eapTlsEnabled',
  Dot1x_Authentication_Username = 'cdmData.ethConfig.dot1xAuthConfig.userName',
  Dot1x_Authentication_Password = 'cdmData.ethConfig.dot1xAuthConfig.password',
  Dot1x_Authentication_ServerId = 'cdmData.ethConfig.dot1xAuthConfig.serverIdentity',
  Dot1x_Authentication_RequireExactMatch = 'cdmData.ethConfig.dot1xAuthConfig.exactServerIdMatch',
  Dot1x_Authentication_OnAuthenticationFailure = 'cdmData.ethConfig.dot1xAuthConfig.blockNetworkOnAuthFailure',
  Dot1x_Authentication_Reauthenticate = 'cdmData.ethConfig.dot1xAuthConfig.reauthenticate',
  // Wifi Direct
  Wifi_Direct_Enable = 'cdmData.enabled',
  Wifi_Direct_Suffix = 'cdmData.ssidSuffix',
  Wifi_Direct_Connection = 'cdmData.connectionMethod',
  Wifi_Direct_Channel = 'cdmData.currentChannel',
  Wifi_Direct_Hide_SSID = 'cdmData.hideSsid',
  Wifi_Direct_Hide_Password = 'cdmData.showPassphrase',
  // App Deployment
  App_Deployment_WorkPath_Enablement = 'cdmData.workpathEnabledState',
  App_Deployment_AppInstallation = 'cdmData.workpathEnabledState',
  // Restrict Color
  Restrict_Color_Enable = 'cdmData.security.colorAccessControl.restrictColor',
  Restrict_User_Permissions = 'cdmData.security.colorAccessControl.customColorAccess.usingPermissionSets',
  Restrict_Application = 'cdmData.security.colorAccessControl.customColorAccess.usingApplicationSettings',
  Default_Permission = 'cdmData.security.colorAccessControl.customColorAccess.allowColorAndQuality',
  Non_Default_Applications = 'cdmData.security.colorAccessControl.applications',
 //Tray Admin
  Tray_Admin = 'cdmData.inputs',
  //StoredDataPinProtection
  StoredDataPinProtection_RequireScanJobStorage = 'cdmData.requirePrintJobPinProtection',
  //WebServices
  HP_WebServicesDCData = 'cdmData.cloudServicesEnabled',
  //CartridgeVeryLowAction
  CartridgeVeryLowAction_Black = 'cdmData.blackVeryLowAction',
  CartridgeVeryLowAction_Color = 'cdmData.colorVeryLowAction',
  //Home Screen Customization - Futuresmart
  DefaultHomeScreenApplication = 'cdmData.defaultHomeScreenApplication',
  EmergencyClientMode = 'cdmData.emergencyClientMode',
  HomeScreenLanguage = 'cdmData.languageSelectionEnabled',
  HomeScreenAlternateKeyboard = 'cdmData.displayAlternateKeyboards',
  //AccessControlDefault
  PermissionSets = 'cdmData.rolePermissionMappings',
  AccessControlDefault = 'cdmData.defaultAuthnAgent',
  AlternateSignInMethod = 'cdmData.alternateSignInMethodsAllowed',
  //UseRequestedTray
  Use_Requested_Tray = 'cdmData.useRequestedTray',
  //UseRequestedTray
  Override_A4_Letter = 'cdmData.a4LetterOverrideEnabled',
  //ManualFeedPrompt
  ManualFeedPrompt = 'cdmData.manualFeedprompt',
  //Tray1 Mode Manual feed
  Tray1_Mode_Manual_feed = 'cdmData.manualFeedEnable',
  System_Location = 'cdmData.systemLocation',
  System_Contact = 'cdmData.systemContact',
  Support_Contact = 'cdmData.supportContact',
  Size_Type_Prompt = 'cdmData.sizeTypeEnabled',
  //ProxyServer
  ProxyServer = 'cdmData.httpProxy.enabled',
  ProxyServer_Address = 'cdmData.httpProxy.address',
  ProxyServer_Port = 'cdmData.httpProxy.port',
  ProxyServer_User = 'cdmData.authentication.userId',
  ProxyServer_Password = 'cdmData.authentication.passwordSet',
  //Bootloader Password
  Bootloader_Password = 'cdmData.passwordSet',
  //Email Scan Settings
  EmailScanSettings = 'cdmData.src.scan.contentType',
}
