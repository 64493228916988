import { refined, refinedDate, refinedArray } from 'src/utils/commonMethods';

export const refinedGetDeviceGroupsAll = (data) => {
  return {
    contents:
      data.contents?.length > 0
        ? data.contents?.map((obj) => ({
            id: refined(obj.id),
          }))
        : [{ id: refined(undefined) }],
  };
};

export const refinedGetDeviceGroupsUngrouped = (data) => {
  return {
    contents:
      data.contents?.length > 0
        ? data.contents?.map((obj) => ({
            id: refined(obj.id),
          }))
        : [{ id: refined(undefined) }],
  };
};

export const refinedGetDeviceGroups = (data) => {
  return {
    contents:
      data.contents?.map((obj) => ({
        id: refined(obj.id),
        name: refined(obj.name),
        devices: obj.devices == null || obj.devices === -1 ? '0' : obj.devices.toString(),
      })) ?? [],
  };
};

export const refinedUseGetTypedCollectionById = (data) => {
  const refinedConnTypes = (data: any) => {
    if (data === undefined || !Array.isArray(data) || data.length === 0) {
      return '--';
    } else {
      const res = [];
      if (data.some((it) => it.includes('print-iot'))) {
        res.push('Cloud');
      }
      if (data.some((it) => it.includes('fleet-proxy'))) {
        res.push('Proxy');
      }
      return res.join(', ');
    }
  };

  const refinedSolutionMetadata = (data: any) => {
    const emptySolutionMetadata = {
      result: 'unknown',
    };
    if (data === null || data === undefined || Object.values(data).length === 0) {
      return [emptySolutionMetadata];
    }
    let objNullChecker = false;
    for (const key in data) {
      if (data[key] === null || data[key] === undefined) {
        objNullChecker = true;
      }
    }
    if (objNullChecker) {
      return [emptySolutionMetadata];
    }

    return Object.values(data)?.map(
      (obj: {
        state: '';
        result: '';
        policyId: '';
        lastAssessed: '';
        securityPolicy: '';
        securityPolicyCount: 0;
      }) => ({
        state: refined(obj.state),
        result: refined(obj.result),
        policyId: refined(obj.policyId),
        lastAssessed: refinedDate(obj.lastAssessed),
        securityPolicy: refined(obj.securityPolicy),
        securityPolicyCount: obj.securityPolicyCount ?? 0,
      }),
    );
  };

  const refinedSolutionMetadataForProxy = (data: any) => {
    if (data === undefined || data === null) {
      return [];
    }

    return Object.values(data)
      .filter(
        (obj: { fleetProxyId: string }) =>
          obj.fleetProxyId !== undefined && obj.fleetProxyId !== null,
      )
      .map((obj: { fleetProxyId: string }) => obj.fleetProxyId);
  };

  const refinedNetworkAdapter = (data: any) => {
    const emptyAdapter = {
      enabled: false,
      hostname: '--',
      ipv4: { address: { ip: '--' }, enabled: false },
      ipv6: { address: { ip: '--' }, enabled: false },
      macAddress: '--',
      type: '--',
    };
    if (data === undefined || data === null || !data.adapters) {
      return [
        {
          ...emptyAdapter,
          name: 'eth0',
        },
        {
          ...emptyAdapter,
          name: 'wifi0',
        },
        {
          ...emptyAdapter,
          name: 'wifi-direct',
        },
      ];
    } else {
      const adapters = data.adapters;

      let wiredEthernet = adapters.find((adapter) => adapter.name.startsWith('eth0'));
      if (wiredEthernet === undefined) {
        wiredEthernet = adapters.find((adapter) => adapter.name.startsWith('eth1'));
        if (wiredEthernet === undefined) {
          wiredEthernet = {
            ...emptyAdapter,
            name: 'eth0',
          };
        }
      }
      wiredEthernet.hostname = refined(wiredEthernet.hostname);
      wiredEthernet.macAddress = refined(wiredEthernet.macAddress?.toUpperCase());
      wiredEthernet.name = refined(wiredEthernet.name);
      wiredEthernet.ipv4 = {
        address: {
          ip: refined(wiredEthernet.ipv4?.address?.ip),
          enabled: wiredEthernet.ipv4?.address?.enabled ?? false,
        },
      };
      wiredEthernet.ipv6 = {
        address: {
          ip: refined(wiredEthernet.ipv6?.address?.ip?.toUpperCase()),
          enabled: wiredEthernet.ipv6?.address.enabled ?? false,
        },
      };

      let wifiStation = adapters.find((adapter) => adapter.name.startsWith('wifi0'));
      if (wifiStation === undefined) {
        wifiStation = adapters.find(
          (adapter) => adapter.name.startsWith('wifi1') && adapter.type !== 'wifiAccessPoint',
        );
        if (wifiStation === undefined) {
          wifiStation = {
            ...emptyAdapter,
            name: 'wifi0',
          };
        }
      }
      wifiStation.hostname = refined(wifiStation.hostname);
      wifiStation.macAddress = refined(wifiStation.macAddress?.toUpperCase());
      wifiStation.name = refined(wifiStation.name);
      wifiStation.ipv4 = {
        address: {
          ip: refined(wifiStation.ipv4?.address?.ip),
          enabled: wifiStation.ipv4?.address?.enabled ?? false,
        },
      };
      wifiStation.ipv6 = {
        address: {
          ip: refined(wifiStation.ipv6?.address?.ip?.toUpperCase()),
          enabled: wifiStation.ipv6?.address?.enabled ?? false,
        },
      };

      let wifiDirect = adapters.filter(
        (adapter) => adapter.name.startsWith('wifi') && adapter.type === 'wifiAccessPoint',
      );

      if (wifiDirect.length > 0) {
        wifiDirect = wifiDirect[0];
        wifiDirect.hostname = refined(wifiDirect.hostname);
        wifiDirect.macAddress = refined(wifiDirect.macAddress?.toUpperCase());
        wifiDirect.name = refined(wifiDirect.name);
        wifiDirect.ipv4 = {
          address: {
            ip: refined(wifiDirect.ipv4?.address?.ip),
            enabled: wifiDirect.ipv4?.address?.enabled ?? false,
          },
        };
        wifiDirect.ipv6 = {
          address: {
            ip: refined(wifiDirect.ipv6?.address?.ip),
            enabled: wifiDirect.ipv6?.address?.enabled ?? false,
          },
        };
      } else {
        wifiDirect = {
          ...emptyAdapter,
          name: 'wifi-direct',
        };
      }

      return [wiredEthernet, wifiStation, wifiDirect];
    }
  };

  return {
    contents:
      data.contents?.map((obj) => ({
        deviceId: refined(obj.deviceId),
        groups:
          obj.groups?.map((innerObj) => ({
            uuid: refined(innerObj.uuid),
            label: refined(innerObj.label),
            parent: refined(innerObj.parent),
          })) ?? [],
        lastUpdatedAt: refinedDate(obj.lastUpdatedAt),
        type: refined(obj.type),
        ownership: {
          accountId: refined(obj.ownership?.accountId),
          ownershipId: refined(obj.ownership?.ownershipId),
        },
        connTypes: refinedConnTypes(obj.connTypes),
        identity: {
          lastUpdatedAt: refinedDate(obj.identity?.lastUpdatedAt),
          serialNumber: refined(obj.identity?.serialNumber),
          makeAndModel: {
            name: refined(obj.identity?.makeAndModel?.name),
            number: refined(obj.identity?.makeAndModel?.number),
            manufacturer: refined(obj.identity?.makeAndModel?.manufacturer),
          },
          location: refined(obj.identity?.location),
          friendlyName: refined(obj.identity?.friendlyName),
          assetNumber: refined(obj.identity?.assetNumber),
          firmwareVersion: refined(obj.identity?.firmwareVersion),
          deviceUuid: refined(obj.identity?.deviceUuid),
        },
        status: {
          lastUpdatedAt: refined(obj.status?.lastUpdatedAt),
          connectionState: obj.status?.connectionState ?? 'offline',
          acceptingJobs: obj.status?.acceptingJobs ?? false,
          printerState: refined(obj.status?.printerState),
          printerStateSeverity: refined(obj.status?.printerStateSeverity),
        },
        software: {
          apps: refinedArray(obj.software?.apps),
        },
        images:
          obj.images?.map((innerObj) => ({
            url: refined(innerObj.url),
          })) ?? [],
        solutionMetadata: refinedSolutionMetadata(obj.solutionMetadata),
        fleetProxyId: refinedSolutionMetadataForProxy(obj.solutionMetadata),
        solutions: obj?.solutions ?? [],
        timestamp: {
          firstRegistrationTime: refinedDate(obj.timestamp?.firstRegistrationTime),
          addedDate: refinedDate(obj.timestamp?.firstRegistrationTime ?? obj.timestamp?.claimTime),
        },
        network: { adapters: refinedNetworkAdapter(obj.network) },
      })) ?? [],
  };
};
