import moment from 'moment'
import { Group, User, Attribute, PolicyTemplate } from 'common/model/api/Policy'
import {
  PolicyAttributeEntitlementEnum,
  allSolutionId
} from 'components/policies/constants'
import { Condition } from 'context/policies/PoliciesErrors'

/**
 * Converts hours from 24h to 12h format
 *
 * @param hours hours in 24h format
 * @param is12HourFormat optional conversion flag
 * @returns {string | number} hours in 12h format
 */
export function hours24to12(
  hours: string | number,
  is12HourFormat = true
): string | number {
  const convert = (hh) => hh % 12 || 12
  return !is12HourFormat
    ? hours
    : typeof hours === 'string'
    ? convert(parseInt(hours)).toString()
    : convert(hours)
}

/**
 * Returns PM flag for hours in 24h format
 *
 * @param hours hours in 24h format
 * @returns {boolean} PM flag
 */
export function isPm(hours: string | number): boolean {
  const convert = (hh) => hh >= 12
  return typeof hours === 'string' ? convert(parseInt(hours)) : convert(hours)
}

/**
 * Converts hours from 12h to 24h format
 *
 * @param hours hours in 12h format
 * @param pm PM flag
 * @param is12HourFormat optional conversion flag
 * @returns {string | number} hours in 24h format
 */
export function hours12to24(
  hours: string | number,
  pm: boolean,
  is12HourFormat = true
): string | number {
  const convert = (hh) => (hh % 12) + (pm ? 12 : 0)
  return !is12HourFormat
    ? hours
    : typeof hours === 'string'
    ? convert(parseInt(hours)).toString()
    : convert(hours)
}

/**
 * Format date/time in common format independently on current locale
 *
 * @param value specifies date/time to be localized
 * @param format value specifies display format
 * @returns {string} with localized date/time
 */
export function formatDate(value?: Date, format?: DATE_FORMAT): string {
  return value ? moment(value).format(format) : ''
}

/**
 * Date & time formats
 */

export enum DATE_FORMAT {
  START_END_TIME = 'LT',
  LAST_MODIFIED = 'lll'
}

/**
 * Common table constants
 */

export const NO_SELECTION = -1
export enum TABLE_CONSTANTS {
  ID = 'id',
  NO_DATA = '--',
  JOINER = ', '
}
export const TABLE_PAGE_SIZES = [
  { value: 5 },
  { value: 25 },
  { value: 50 },
  { value: 100 },
  { value: 500 }
]

/**
 * Boolean constants
 */

export function isTrueString(value) {
  return value === 'true'
}

/**
 * Week day constants
 */

export const WEEK_DAYS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
]

/**
 * Parse JSON safely
 *
 * @param text JSON text string
 * @param defResult error result
 * @returns {object} parsed object
 */

export function jsonParse(
  text: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defResult: Record<string, string> | any[] = []
) {
  try {
    return text ? JSON.parse(text) : defResult
  } catch (SyntaxError) {
    return defResult
  }
}

/**
 * Get group name, localized if necessary
 *
 * @param group device group
 * @param t localization
 * @returns {string} group name: localized or user-defined
 */

export function getGroupName(group: Group, t): string {
  return ['All', 'Ungrouped'].includes(group.groupName) &&
    group['builtIn'] == true // optional
    ? t(`policy.settings.groups.${group.groupName.toLowerCase()}`)
    : group.groupName
}

/**
 * Get username if possible
 *
 * @param user user object
 * @returns {string} user name: first + last, id or empty string
 */

export function getUserName(user: User): string {
  return user && user.givenName && user.familyName
    ? `${user.givenName} ${user.familyName}`
    : user?.userId || ''
}

/**
 * Check entitlements
 *
 * @param entitlements entitlements
 * @returns {boolean} whether to display "Advanced" or not
 */

export function isAdvanced(entitlements: string[]): boolean {
  return (
    entitlements?.length === 1 &&
    entitlements[0] === PolicyAttributeEntitlementEnum.ADVANCED
  )
}

/**
 * Merge all attributes from templates
 *
 * @param templates policy templates
 * @returns {Object} array of attributes with entitlement info
 */

export function getAttributesWithEntitlement(
  templates: PolicyTemplate[]
): Attribute[] {
  const attributes = []
  templates
    .filter((x) => x.entitlement || x.solutionId === allSolutionId)
    .forEach((template) =>
      template.attributes.forEach((attr) => {
        let found = attributes.find((a) => a.name === attr.name)
        if (!found) {
          attr.metadata.entitlements = []
          attributes.push(attr)
          found = attr
        }
        if (template.entitlement && template.solutionId !== allSolutionId) {
          found.metadata.entitlements.push(template.entitlement)
          attr.metadata.entitlements = found.metadata.entitlements
        }
      })
    )
  return attributes
}

/**
 * Advanced method to display toast (add or update)
 *
 * @param toast useToast React method
 * @returns {Object} lambda to display toast
 */

export function getDisplayToast(toast) {
  const { addToast, toasts, updateToast } = toast()
  return (id: string, text: string, type = 'positive', subtitle = '') => {
    if (
      toasts &&
      toasts.length > 0 &&
      toasts.findIndex((item) => item.id === id) >= 0
    ) {
      updateToast(id, { id, text, type, subtitle })
    } else {
      addToast({ id, text, type, subtitle })
    }
  }
}

/**
 * Returns table selection status
 *
 * @param selected number of selected items
 * @param total total number of items
 * @returns {string} one of Veneer table constants
 */

export function getRowSelectAllState(selected: number, total: number): string {
  return !selected ? 'none' : selected === total ? 'all' : 'indeterminated'
}

/**
 * Sort array alphabetically
 *
 * @param items array of objects to sort
 * @param picker object field picker
 * @param order sorting order
 * @returns {Object} same array of objects, now sorted
 */

export function abcSort(
  items: unknown[],
  picker: (any) => string,
  order?: string
): unknown[] {
  const comparator = (a, b) => (a === b ? 0 : a > b ? 1 : -1)
  return items.sort(
    order === 'ascending'
      ? (a, b) => comparator(picker(b), picker(a))
      : (a, b) => comparator(picker(a), picker(b)) // descending is def order
  )
}

/**
 * Check whether the given input satisfies the conditions.
 *
 * @param errors array of conditions to check the input.
 * @param value input which needs to be checked.
 * @returns {string, Object} returns the error string and optional parameter.
 */
export function getError(
  errors: Condition[],
  value: string
): { message: string; params } {
  if (!errors) {
    return null
  }
  // TODO: treating null as empty string affects possible case when rule/value/check are testing by null
  //  It is safe while processing text fields
  const checkValue = value || ''
  const foundError = errors.find(
    (error) =>
      (error.value !== undefined && error.value === checkValue) ||
      (error.rule !== undefined && error.rule.test(checkValue)) ||
      (error.check && error.check(checkValue))
  )
  return foundError
    ? { message: foundError.message, params: foundError.params }
    : null
}

/**
 * Create base64Value from file
 *
 * @param file file object
 * @returns {string} base64-encoded string
 */

export function generateBase64Value(file): string {
  return file?.target?.result?.toString()?.split(',')[1]
}

/**
 * Calculate size of file from base64 string
 *
 * @param base64String base64-encoded string
 * @returns {number} size of file in KB
 */

export function calculateSizeFromBase64(base64String) {
  const padding = base64String.endsWith('==') ? 2 : +base64String.endsWith('=')
  return (base64String.length / 4) * 3 - padding
}

/**
 * Fixes outdated WX theme for Veneer v3.105+
 *
 * @param themeProps theme properties
 * @returns corrected theme properties
 */
export function fixTheme(themeProps) {
  let props = themeProps
  // Newer Veneer expects background container object, not just one color as it was before v3.105
  const light = props.customSemantics?.color?.light?.background?.container
  if (light && !light.default) {
    // fix light theme if needed
    props = {
      ...props,
      customSemantics: {
        ...props.customSemantics,
        color: {
          ...props.customSemantics.color,
          light: {
            ...props.customSemantics.color.light,
            background: {
              ...props.customSemantics.color.light.background,
              container: {
                default: '#ffffff',
                hover: '#f5f5f5',
                active: '#f0f0f0'
              }
            }
          }
        }
      }
    }
  }
  const dark = props.customSemantics?.color?.dark?.background?.container
  if (dark && !dark.default) {
    // fix dark theme if needed
    props = {
      ...props,
      customSemantics: {
        ...props.customSemantics,
        color: {
          ...props.customSemantics.color,
          dark: {
            ...props.customSemantics.color.dark,
            background: {
              ...props.customSemantics.color.dark.background,
              container: {
                default: '#292929',
                hover: '#303030',
                active: '#404040'
              }
            }
          }
        }
      }
    }
  }
  return props
}

/**
 * Generate Random Password
 *
 * @param length password length
 * @param lower include lower case
 * @param upper include upper case
 * @param digits include digits
 * @param punctuation include punctuation
 * @returns {string} generated password
 */

export const generateRandomPassword = (
  length = 16,
  lower = true,
  upper = true,
  digits = true,
  punctuation = true
) => {
  const LOWER = 'abcdefghijklmnopqrstuvwxyz'
  const UPPER = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const DIGITS = '0123456789'
  const PUNCTUATION = '?=.*[!@#$%^&*]'
  let charSet = ''
  if (lower) {
    charSet += LOWER
  }
  if (upper) {
    charSet += UPPER
  }
  if (digits) {
    charSet += DIGITS
  }
  if (punctuation) {
    charSet += PUNCTUATION
  }
  if (!charSet.length) {
    return null
  }

  let password = ''
  const randomArray = new Uint16Array(length)
  window.crypto.getRandomValues(randomArray)
  randomArray.forEach((x) => (password += charSet[x % charSet.length]))

  return password
}
