import { useTableHandler } from 'src/hooks/useTableHandler';
import { useStoreState } from 'src/store/useStoreState';
import { toTitleCase } from 'src/utils/commonMethods';
import { useEffect } from 'react';
import { useQueryParams } from 'src/hooks/useQueryParams';
import { useShellRootContext } from 'src/contexts/ShellRoot';

export const useFilter = (columnConfig) => {
  const { tableHandler } = useTableHandler();
  const { devicesState } = useStoreState();
  const { cfgApiRequestOptions } = devicesState;
  const { isFromApps, appName } = useShellRootContext();
  const [getQueryParams] = useQueryParams();

  const idName = 'deviceId';

  useEffect(() => {
    if (!tableHandler.handleFilterOption) return;

    if (isFromApps) {
      tableHandler.handleFilterOption({ target: { name: `apps: ${appName}` } });
    } else {
      const queryParams: any = getQueryParams();

      if (queryParams.connectivity) {
        tableHandler.handleFilterOption({
          target: { name: `connectionState: ${toTitleCase(queryParams.connectivity)}` },
        });
      }

      if (queryParams.showAssessment) {
        if (queryParams.assessmentType.includes('compliant')) {
          tableHandler.handleFilterOption({
            target: { name: `policyCompliance: ${toTitleCase(queryParams.assessmentType)}` },
          });
        } else if (
          queryParams.assessmentType !== 'all' &&
          queryParams.assessmentType !== 'printerFleetCompliance'
        ) {
          tableHandler.handleFilterOption({
            target: { name: `assessment: ${toTitleCase(queryParams.assessmentType)}` },
          });
        }
      }
    }
  }, []);

  const getSliceEnd = (option, length) => {
    const totalPageCount = Math.floor(length / option.limit) + 1;
    const currentPage = option.offset;
    if (currentPage + 1 < totalPageCount) {
      return (currentPage + 1) * option.limit;
    } else {
      return length;
    }
  };

  const recalculatePage = (newFilteredItems, currentPage) => {
    const newPageOption = {
      offset: currentPage - 1,
      limit: cfgApiRequestOptions.limit,
    };
    const lastIndex = getSliceEnd(newPageOption, newFilteredItems.length);
    return newFilteredItems.slice(newPageOption.offset * newPageOption.limit, lastIndex);
  };

  const filter = (filters, items) => {
    const contents = tableHandler.getColumnContents(items);
    const entries = Object.entries(contents).map(([id, entry]) => {
      const val = entry;
      val['id'] = id;
      return val;
    });
    const result = [];

    items.forEach((item, idx) => {
      entries[idx]['apps'] = item.software.apps.map((app) => app.title.toLowerCase());
    });

    filters.forEach((filter) => {
      const [key, values] = filter; //ex) 'connectionState': ['연결성']
      const lowerCaseVal = values.map((val) => val.toLowerCase());

      for (const device of entries) {
        if (_isElementInArrayOrString(lowerCaseVal, device[key])) {
          result.push(device);
        }
      }
    });
    const IdList = result.map((item) => item.id);
    return items.filter((item) => IdList.includes(item[idName]));
  };

  const triggerFilter = (totalDevices, tableFilter, currentPage) => {
    const filters = JSON.parse(tableFilter);

    if (!filters || filters.length == '') {
      return { pagedItems: totalDevices, count: totalDevices.length, valid: true };
    }

    if (!totalDevices || (totalDevices && totalDevices.length == 0)) {
      return { pagedItems: [], count: 0, valid: false };
    }

    const newFilteredItems = filter(filters, totalDevices);
    const newPagedItems = recalculatePage(newFilteredItems, currentPage);

    return {
      pagedItems: newPagedItems,
      entireItems: newFilteredItems,
      count: newFilteredItems.length,
      valid: true,
    };
  };

  const _isElementInArrayOrString = (a: string[], b: string[] | string): boolean => {
    if (Array.isArray(b)) {
      return a.some((element) => b.includes(element));
    } else if (typeof b === 'string') {
      return a.some((element) => b.toLowerCase().includes(element));
    }
    return false;
  };

  return { triggerFilter };
};
