import { service } from 'src/utils/constants';
import { getProps } from 'src/utils/commonMethods';
import {
  Stack,
  FleetMgtClient,
  AppDeployClient,
  CollectionClient,
  DeviceCacheClient,
  ContentBrokerClient,
  FleetProxyConnectorClient,
} from '@jarvis/react-mfe-component/dist/src';

export const useApiClient = () => {
  const localProps = getProps();
  const stack = localProps.stack;
  const authProvider = localProps.authProvider;

  const getStackPrefix = (stack: Stack): string => {
    switch (stack) {
      case Stack.dev:
        return 'dev';
      case Stack.pie:
        return 'pie';
      case Stack.stage:
        return 'stage';
      case Stack.prod:
        return '';
      default:
        return '';
    }
  };

  const getCustomStackPrefix = (stack: Stack): string => {
    switch (stack) {
      case Stack.dev:
        return 'dev';
      case Stack.pie:
        return 'pie';
      case Stack.stage:
        return 'stg';
      case Stack.prod:
        return 'pro';
      default:
        return '';
    }
  };

  const getApiUrl = (hostPrefix: string, basePath: string): string => {
    const stackPrefix = getStackPrefix(stack);
    const defaultBaseUrl = 'us1.api.ws-hp.com';

    return (
      'https://' +
      (hostPrefix.length ? hostPrefix + '.' : '') +
      (stackPrefix.length ? stackPrefix + '-' : '') +
      defaultBaseUrl +
      basePath
    );
  };

  const getCustomApiUrl = (hostPrefix: string, basePath: string): string => {
    const defaultBaseUrl = 'tropos-rnd.com';

    return 'https://' + (hostPrefix.length ? hostPrefix + '.' : '') + defaultBaseUrl + basePath;
  };

  const getApiClient = (serviceName) => {
    let client;
    const fleetMgtApiEndPoint = getApiUrl('', '/fleetmgt/v1');
    const appDeployApiEndPoint = getCustomApiUrl(
      `stratus-${getCustomStackPrefix(stack)}`,
      '/app-deploy-api/v1',
    );
    const contentBrokerEndpoint = getApiUrl('', '/content-broker-api/v1');

    switch (serviceName) {
      case service.appDeploy:
        client = new AppDeployClient(appDeployApiEndPoint, authProvider);
        break;

      case service.collection:
        client = new CollectionClient(stack, authProvider);
        break;

      case service.deviceCache:
        client = new DeviceCacheClient(stack, authProvider);
        break;

      case service.fleetMgt:
        client = new FleetMgtClient(fleetMgtApiEndPoint, authProvider);
        break;

      case service.contentBroker:
        client = new ContentBrokerClient(contentBrokerEndpoint, authProvider);
        break;

      case service.fleetProxy:
        client = new FleetProxyConnectorClient(stack, authProvider);
        break;
    }

    return client;
  };

  return {
    getApiClient,
  };
};

export default useApiClient;
