import React, { useState, useMemo, useContext } from 'react'
import { Button, ButtonGroup, Modal, Select } from '@veneer/core'
import LabelRadioButtons from 'common/controls/labelRadioButtons'
import LabelSelect from 'common/controls/labelSelect'
import configContext from 'context/config/configContext'
import { WEEK_DAYS, hours24to12, isPm, hours12to24 } from 'common/utilities'
import TimeItems from 'context/policies/dropboxItems/TimeItems'
import { FlexColumn, FlexRow, TextBlack } from 'styles/styles'
import 'components/policies/modal/index.scss'
import 'styles/global.scss'

export enum WeeklyScheduleEnum {
  TYPE = 'type',
  TYPE_SLEEP = 'sleep',
  TYPE_WAKE = 'wake',
  DAYS = 'days',
  HOUR = 'hour',
  MINUTE = 'minute'
}

const WeeklyScheduleModal = (props) => {
  const { value, values, edit, onClose, onChange, getLocalized } = props
  const [inputValue, setInputValue] = useState(value)
  const [error, setError] = useState(null)
  const { is12HourFormat } = useContext(configContext)

  const setData = (x) => {
    checkError(x)
    setInputValue((prevValue) => ({ ...prevValue, ...x }))
  }

  const checkError = (x?) => {
    let err = ''
    const val = { ...inputValue, ...x }
    if (!val[WeeklyScheduleEnum.DAYS]?.length) {
      err = 'error-week-day-required'
    } else if (
      values.some(
        (x) =>
          val[WeeklyScheduleEnum.HOUR] === x[WeeklyScheduleEnum.HOUR] &&
          val[WeeklyScheduleEnum.MINUTE] === x[WeeklyScheduleEnum.MINUTE] &&
          val[WeeklyScheduleEnum.DAYS].some((y) =>
            x[WeeklyScheduleEnum.DAYS].includes(y)
          )
      )
    ) {
      err = 'error-same-day-time'
    }
    setError(err)
    return !err
  }

  const setDays = ({ value: id }) => {
    const newDays =
      inputValue[WeeklyScheduleEnum.DAYS]?.filter((x) => x !== id) || []
    if (newDays.length === inputValue[WeeklyScheduleEnum.DAYS]?.length) {
      newDays.push(id)
      newDays.sort()
    }
    setData({ [WeeklyScheduleEnum.DAYS]: newDays })
  }

  const clearDays = () => setData({ [WeeklyScheduleEnum.DAYS]: [] })

  const onSave = () => {
    if (checkError()) {
      onChange(inputValue)
      onClose()
    }
  }

  const saveDisabled = useMemo(
    () => JSON.stringify(inputValue) === JSON.stringify(value),
    [inputValue, value]
  )

  const typeOptions = [
    {
      value: WeeklyScheduleEnum.TYPE_SLEEP,
      label: getLocalized('sleep-time')
    },
    {
      value: WeeklyScheduleEnum.TYPE_WAKE,
      label: getLocalized('wake-time')
    }
  ]

  const amPmOptions = TimeItems.amPms.map((x) => {
    return { ...x, label: getLocalized(x.label) }
  })

  const weekDays = WEEK_DAYS.map((day, i) => ({
    label: getLocalized('policy.settings.week-days.' + day),
    value: (i + 1).toString()
  }))

  return (
    <Modal
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      title={getLocalized(
        edit ? 'weekly-schedule-edit' : 'weekly-schedule-add'
      )}
      className={'small-policy-modal'}
      data-testid={'id-weekly-schedule-modal'}
      footer={
        <ButtonGroup>
          <Button onClick={onSave} disabled={saveDisabled}>
            {getLocalized(edit ? 'common.save' : 'common.add')}
          </Button>
          <Button appearance={'secondary'} onClick={onClose}>
            {getLocalized('common.cancel')}
          </Button>
        </ButtonGroup>
      }
    >
      <p>
        {getLocalized(
          edit
            ? 'weekly-schedule-edit-description'
            : 'weekly-schedule-add-description'
        )}
      </p>

      <FlexColumn className={'paddingTop16'}>
        <LabelRadioButtons
          label={getLocalized('event-type')}
          onChange={(_, val) =>
            setInputValue((x) => ({ ...x, [WeeklyScheduleEnum.TYPE]: val }))
          }
          value={inputValue[WeeklyScheduleEnum.TYPE]}
          id={WeeklyScheduleEnum.TYPE}
          options={typeOptions}
        />

        <FlexColumn className={'paddingTop16 devSettingsIndent1'}>
          <TextBlack className={'marginBottom4'}>
            {getLocalized('time')}
          </TextBlack>
          <FlexRow className={'alignCenter paddingBottom16'}>
            <Select
              options={is12HourFormat ? TimeItems.hours12 : TimeItems.hours24}
              placeholder={getLocalized('common.select-option')}
              className={'formatSelect'}
              id={WeeklyScheduleEnum.HOUR}
              clearIcon={false}
              value={[
                hours24to12(inputValue[WeeklyScheduleEnum.HOUR], is12HourFormat)
              ]}
              onChange={(data) =>
                setData({
                  [WeeklyScheduleEnum.HOUR]: hours12to24(
                    data.value,
                    isPm(inputValue[WeeklyScheduleEnum.HOUR]),
                    is12HourFormat
                  )
                })
              }
            />
            <TextBlack className={'paddingLeft4'}>
              {getLocalized('policy.settings.time.separator')}
            </TextBlack>
            <Select
              options={TimeItems.minutesBy10}
              placeholder={getLocalized('common.select-option')}
              id={WeeklyScheduleEnum.MINUTE}
              clearIcon={false}
              className={'formatSelect paddingLeft4'}
              value={[inputValue[WeeklyScheduleEnum.MINUTE]]}
              onChange={(data) =>
                setData({ [WeeklyScheduleEnum.MINUTE]: data.value })
              }
            />
            {is12HourFormat && (
              <Select
                options={amPmOptions}
                placeholder={getLocalized('common.select-option')}
                clearIcon={false}
                className={'formatSelect paddingLeft4'}
                value={[
                  amPmOptions[isPm(inputValue[WeeklyScheduleEnum.HOUR]) ? 1 : 0]
                    .value
                ]}
                onChange={({ value }) =>
                  setData({
                    [WeeklyScheduleEnum.HOUR]: hours12to24(
                      hours24to12(inputValue[WeeklyScheduleEnum.HOUR]),
                      amPmOptions[1].value === value
                    )
                  })
                }
              />
            )}
          </FlexRow>
          <LabelSelect
            multiple
            id={'weekly-schedule-days'}
            label={getLocalized('sleep-schedule')}
            className={'maxTextWidth'}
            options={weekDays}
            placeholder={getLocalized('common.select-option')}
            value={inputValue[WeeklyScheduleEnum.DAYS] || []}
            error={!!error}
            helperText={error ? getLocalized(error) : null}
            onChange={setDays}
            onClear={clearDays}
          />
        </FlexColumn>
      </FlexColumn>
    </Modal>
  )
}
export default WeeklyScheduleModal
