import { Item } from 'context/policies/PoliciesConfiguration'
import PolicyConstraints, {
  ConstraintsResourceEnum,
  ConstraintsSettingEnum
} from 'context/constraints/Constraints'

interface PermItem extends Item {
  parentId?: string
}

const perm = (value, label, parentId?): PermItem => ({
  value,
  label: 'policy.settings.permissions.' + label,
  option: value,
  parentId
})

export default class DevicePermissionItems {
  static controlPanelPermissions = [
    perm('3dfe6950-5cf9-41c2-a3b2-6154868ab45d', 'admin'),
    perm(
      '4fdcd8b2-6e7e-44da-924a-85981a727c1b',
      'admin-network-settings-menu',
      '3dfe6950-5cf9-41c2-a3b2-6154868ab45d'
    ),
    perm(
      '4692a241-8998-4aa8-9d31-1f8ccb43ea14',
      'admin-network-protocols-menu',
      '4fdcd8b2-6e7e-44da-924a-85981a727c1b'
    ),
    perm(
      '1a2d31a0-b6c8-4497-a708-707792bd2609',
      'admin-manage-supplies-menu',
      '3dfe6950-5cf9-41c2-a3b2-6154868ab45d'
    ),
    perm(
      '8e5bbd50-c488-4791-9bd1-c84b339014e5',
      'admin-reset-supplies-menu',
      '1a2d31a0-b6c8-4497-a708-707792bd2609'
    ),
    perm(
      '3c06acc4-f0e9-4248-8933-4aa500cee7b8',
      'admin-copy-print-settings-menu',
      '3dfe6950-5cf9-41c2-a3b2-6154868ab45d'
    ),
    perm(
      'fbd10c44-f550-498a-bfa0-b7e634afa551',
      'admin-manage-stored-jobs',
      '3c06acc4-f0e9-4248-8933-4aa500cee7b8'
    ),
    perm(
      'd41cc382-c023-46dc-b722-4178a599901a',
      'admin-manage-trays-menu',
      '3c06acc4-f0e9-4248-8933-4aa500cee7b8'
    ),
    perm(
      '7b8e9297-1c88-4316-b1c8-ed7a5b8eb4c7',
      'admin-print-from-usb-mass-storage-settings-menu',
      '3c06acc4-f0e9-4248-8933-4aa500cee7b8'
    ),
    perm(
      '07799ea1-52ea-4cc8-8dbf-1458dc2f39d1',
      'admin-print-options-menu',
      '3c06acc4-f0e9-4248-8933-4aa500cee7b8'
    ),
    perm(
      'dceabb35-81d0-4c5f-a2f8-4c9c318b043b',
      'admin-print-quality-menu',
      '3c06acc4-f0e9-4248-8933-4aa500cee7b8'
    ),
    perm(
      '7389ff49-20e5-41ff-9b96-5c1df60d7e67',
      'admin-print-settings-men',
      '3c06acc4-f0e9-4248-8933-4aa500cee7b8'
    ),
    perm(
      '2565f335-e4ad-4cde-966d-30d9f3f1999f',
      'admin-digital-send-settings-menu',
      '3dfe6950-5cf9-41c2-a3b2-6154868ab45d'
    ),
    perm(
      'a639160a-7b18-4c02-b4a4-00b9026f00d6',
      'admin-digital-send-email-settings-menu',
      '2565f335-e4ad-4cde-966d-30d9f3f1999f'
    ),
    perm(
      '52099d81-6fd5-481f-84ef-588c8ce39a3c',
      'send-to-email-configure-setup',
      'a639160a-7b18-4c02-b4a4-00b9026f00d6'
    ),
    perm(
      '4d1dc259-0d38-4bfd-b121-b11c22bc67bc',
      'admin-save-to-usb-mass-storage-settings-menu',
      '2565f335-e4ad-4cde-966d-30d9f3f1999f'
    ),
    perm(
      '74d83bcd-0cd0-4f89-aca5-39df93cb0b0d',
      'admin-send-to-folder-settings-menu',
      '2565f335-e4ad-4cde-966d-30d9f3f1999f'
    ),
    perm(
      'c980a35a-3e93-4971-b8c6-f76cbfbeedcf',
      'configure-digital-send-service-setup',
      '2565f335-e4ad-4cde-966d-30d9f3f1999f'
    ),
    perm(
      '965dabb4-8d1a-42df-943a-74c368a4c144',
      'admin-fax-settings-menu',
      '3dfe6950-5cf9-41c2-a3b2-6154868ab45d'
    ),
    perm(
      'aa531833-f8e7-453b-b81d-fb827098c5f0',
      'admin-fax-archive-and-forwarding-menu',
      '965dabb4-8d1a-42df-943a-74c368a4c144'
    ),
    perm(
      '91241b7e-cc42-11d7-b4b1-00306e482828',
      'admin-fax-receive-settings-menu',
      '965dabb4-8d1a-42df-943a-74c368a4c144'
    ),
    perm(
      '68147198-5521-46ed-bec1-1b395f993f1a',
      'fax-configure-printing-schedule',
      '91241b7e-cc42-11d7-b4b1-00306e482828'
    ),
    perm(
      '5f85357c-1202-4d1b-8445-e0224456c57a',
      'fax-configure-receive-blocked-fax-number',
      '91241b7e-cc42-11d7-b4b1-00306e482828'
    ),
    perm(
      '3a9de7c7-5e0d-469c-849a-5920881810b4',
      'fax-configure-receive-default-job-options',
      '91241b7e-cc42-11d7-b4b1-00306e482828'
    ),
    perm(
      'cca06313-14f0-4e13-a2cf-97c38abb1d73',
      'fax-configure-receive-setup',
      '91241b7e-cc42-11d7-b4b1-00306e482828'
    ),
    perm(
      'eca4c819-4f03-458c-9aff-62f4be730e0b',
      'admin-fax-send-settings-menu',
      '965dabb4-8d1a-42df-943a-74c368a4c144'
    ),
    perm(
      '69dd2781-34e5-4abe-b170-c7bf2ef5696c',
      'fax-general-fax-send-settings-menu',
      'eca4c819-4f03-458c-9aff-62f4be730e0b'
    ),
    perm(
      'bca81cac-3f14-4f32-ac00-185dd58dc3e1',
      'fax-configure-send-setup-wizard',
      '965dabb4-8d1a-42df-943a-74c368a4c144'
    ),
    perm(
      'a062b6d9-8475-4663-8bbf-c9ed15e8d460',
      'admin-general-settings-menu',
      '3dfe6950-5cf9-41c2-a3b2-6154868ab45d'
    ),
    perm(
      'defd075a-d6a7-498e-9efb-4b9465a8c091',
      'admin-date-time-menu',
      'a062b6d9-8475-4663-8bbf-c9ed15e8d460'
    ),
    perm(
      'cdd8157e-c605-473d-939b-98994607a436',
      'admin-display-settings-menu',
      'a062b6d9-8475-4663-8bbf-c9ed15e8d460'
    ),
    perm(
      'fdc59fb8-3a54-426d-9f5a-2d9087233bb0',
      'admin-energy-settings-menu',
      'a062b6d9-8475-4663-8bbf-c9ed15e8d460'
    ),
    perm(
      'b532012c-4017-472a-97d7-625f628bf75b',
      'admin-restore-factory-settings-menu',
      'a062b6d9-8475-4663-8bbf-c9ed15e8d460'
    ),
    perm(
      '74425807-8557-49ae-820c-ce203f15e96b',
      'hp-configure-device-usb',
      'a062b6d9-8475-4663-8bbf-c9ed15e8d460'
    ),
    perm('34876b06-05be-4044-b61c-40cca9dfe4cb', 'admin-reports-menu'),
    perm(
      '7bb1b399-d731-4f3e-a013-82ce9ea9434f',
      'admin-configuration-status-men',
      '34876b06-05be-4044-b61c-40cca9dfe4cb'
    ),
    perm(
      '978af7de-4501-4709-b4e6-ecc056d41917',
      'admin-supplies-status-menu',
      '7bb1b399-d731-4f3e-a013-82ce9ea9434f'
    ),
    perm(
      '7e162dc5-a9af-4af2-b3a3-48c2d6e6bb7b',
      'admin-usage-menu',
      '7bb1b399-d731-4f3e-a013-82ce9ea9434f'
    ),
    perm(
      'c4fc5dcf-d03f-46e0-8e42-085ec5c3c108',
      'print-configuration-page',
      '7bb1b399-d731-4f3e-a013-82ce9ea9434f'
    ),
    perm(
      'a12676bb-aa09-44bf-9608-a8d14cde50d7',
      'print-file-directory',
      '7bb1b399-d731-4f3e-a013-82ce9ea9434f'
    ),
    perm(
      'f22bab25-0bde-4983-9e28-24e536f13981',
      'admin-fax-reports-menu',
      '34876b06-05be-4044-b61c-40cca9dfe4cb'
    ),
    perm(
      '5d1f0b3f-131e-4901-9807-a17d21ed11ba',
      'print-blocked-fax-list',
      'f22bab25-0bde-4983-9e28-24e536f13981'
    ),
    perm(
      '8591cb93-584c-43db-af22-eb9a817add9c',
      'print-fax-activity-log',
      'f22bab25-0bde-4983-9e28-24e536f13981'
    ),
    perm(
      '84fc50f6-338d-4f5c-baba-e3a90418f364',
      'print-fax-billing-codes-report',
      'f22bab25-0bde-4983-9e28-24e536f13981'
    ),
    perm(
      'd13fe446-6403-40c4-8f95-c734978d3dd3',
      'print-last-fax-call-report',
      'f22bab25-0bde-4983-9e28-24e536f13981'
    ),
    perm(
      '81fbf819-ac36-481d-8736-c66a20a3ce75',
      'admin-other-pages-menu',
      '34876b06-05be-4044-b61c-40cca9dfe4cb'
    ),
    perm('cedab422-33b3-4638-b6a1-604e54525215', 'copy'),
    perm(
      '57325ed2-49cd-4253-86f4-dc9af7103bda',
      'admin-copy-settings-menu',
      'cedab422-33b3-4638-b6a1-604e54525215'
    ),
    perm(
      'cc00c027-fe28-48fc-b26c-a83d67da66ea',
      'copy-edit-reduce-enlarge-presets',
      'cedab422-33b3-4638-b6a1-604e54525215'
    ),
    perm(
      '1fc3f42a-f887-461c-a27a-66a73156308a',
      'copy-load-quicksets',
      'cedab422-33b3-4638-b6a1-604e54525215'
    ),
    perm(
      '6b27ea0c-5311-4ce7-b0a8-aa4d21afe76b',
      'copy-save-quicksets',
      'cedab422-33b3-4638-b6a1-604e54525215'
    ),
    perm(
      '7deecd46-5c05-4ce7-9c23-0c6fa1d5fc01',
      'hp-copy-simplex-output',
      'cedab422-33b3-4638-b6a1-604e54525215'
    ),
    perm('900a0d59-533a-497e-8e89-2b5bc898d5cc', 'contacts'),
    perm(
      'ed979c88-86e4-422a-9842-6da6d53b431b',
      'fax-edit-speed-dial',
      '900a0d59-533a-497e-8e89-2b5bc898d5cc'
    ),
    perm(
      '1047e094-c564-4bcc-8a14-370bfddfb796',
      'modify-private-address-books',
      '900a0d59-533a-497e-8e89-2b5bc898d5cc'
    ),
    perm(
      '6d777dcb-f62b-4cec-b536-c39078e14fc5',
      'modify-public-address-book',
      '900a0d59-533a-497e-8e89-2b5bc898d5cc'
    ),
    perm('1d370ecf-fb74-44ae-8934-39740a6911ed', 'device-maintenance'),
    perm(
      'a9e3da1b-8173-419f-bd6a-2cc325567c4c',
      'admin-troubleshooting-menu',
      '1d370ecf-fb74-44ae-8934-39740a6911ed'
    ),
    perm(
      '0d561c63-8717-4dbe-9b45-b7dae135f6b1',
      'admin-clear-event-log',
      'a9e3da1b-8173-419f-bd6a-2cc325567c4c'
    ),
    perm(
      'efb5ad4c-591c-4c94-bacf-be5d87c917a3',
      'admin-retrieve-fax-diagnostic-data',
      'a9e3da1b-8173-419f-bd6a-2cc325567c4c'
    ),
    perm(
      'e4835c1f-7f0e-446b-9fc2-f18ef145698f',
      'retrieve-diagnostic-data',
      'a9e3da1b-8173-419f-bd6a-2cc325567c4c'
    ),
    perm('44aa632b-cfa3-4c10-8cab-697a9bef610b', 'fax'),
    perm(
      '016d46c6-5a3a-4646-910d-0ede122b949e',
      'fax-configure-send-default-job-options',
      '44aa632b-cfa3-4c10-8cab-697a9bef610b'
    ),
    perm(
      '8b99a8ad-9432-48a1-baca-22b0df6c65e4',
      'fax-load-quicksets',
      '44aa632b-cfa3-4c10-8cab-697a9bef610b'
    ),
    perm(
      'c33fdc04-682f-43ab-9f70-fdedfdc1d74b',
      'fax-save-quicksets',
      '44aa632b-cfa3-4c10-8cab-697a9bef610b'
    ),
    perm(
      '922cd28d-77a8-47a8-85c4-f117f5f69c82',
      'hp-fax-edit-billing-code',
      '44aa632b-cfa3-4c10-8cab-697a9bef610b'
    ),
    perm('A935C131-CBE6-4d09-9AC2-624C12A9033B', 'hp-command-center'),
    perm('a3d696df-b7ff-4d3d-9969-5cd7f18c0c92', 'hp-save-to-sharepoint'),
    perm(
      '19363c7c-235e-4c78-8be3-fb4673d59b25',
      'hp-save-to-sharepoint-edit-path',
      'a3d696df-b7ff-4d3d-9969-5cd7f18c0c92'
    ),
    perm('87550e5e-f927-11df-950b-00306e48bff7', 'job-storage-print'),
    perm(
      'd544b0ba-a3fb-4911-a82b-bf4f891b3308',
      'job-storage-access-stored-fax-job',
      '87550e5e-f927-11df-950b-00306e48bff7'
    ),
    perm(
      'a4dfaedc-7724-48ea-948a-5cd7b11407f0',
      'job-storage-delete-any-private-job',
      '87550e5e-f927-11df-950b-00306e48bff7'
    ),
    perm(
      '535ea693-db6d-4beb-b548-f1693460eab3',
      'job-storage-view-all-jobs',
      '87550e5e-f927-11df-950b-00306e48bff7'
    ),
    perm('56ce9217-377d-4d5c-a950-a2ad37c07882', 'job-status'),
    perm(
      '3c40cf32-1d3d-4051-9ba2-0a7b839b0288',
      'job-status-manage-all-jobs',
      '56ce9217-377d-4d5c-a950-a2ad37c07882'
    ),
    perm(
      '4068badd-7fa0-4c8f-b875-bf7e04dec26e',
      'job-status-promote-job',
      '56ce9217-377d-4d5c-a950-a2ad37c07882'
    ),
    perm(
      'ef4dbcd9-34d5-46e2-af53-1bacda9e2a34',
      'job-status-view-all-jobs',
      '56ce9217-377d-4d5c-a950-a2ad37c07882'
    ),
    perm('12642a24-5e74-41a2-a154-20d20436abee', 'print-from-usb-mass-storage'),
    perm('d6c8dbb4-0cea-4147-b8a7-0cffd9c3ca90', 'job-storage-save'),
    perm('65acca51-619d-4e29-b1d0-6414e52f908b', 'send-to-folder'),
    perm(
      'f69557b5-82f6-4269-894c-1b4046a0a92a',
      'send-to-folder-configure-default-job-settings',
      '65acca51-619d-4e29-b1d0-6414e52f908b'
    ),
    perm(
      'ee19ffb2-d93c-42ab-a23b-b868a63304a3',
      'send-to-folder-edit-path',
      '65acca51-619d-4e29-b1d0-6414e52f908b'
    ),
    perm(
      '3ecf76a7-4ebe-4265-8cdf-22f4f359daf9',
      'send-to-folder-load-quicksets',
      '65acca51-619d-4e29-b1d0-6414e52f908b'
    ),
    perm(
      'f753d9a7-c4fc-47c8-abd3-49d901528f29',
      'send-to-folder-save-quicksets',
      '65acca51-619d-4e29-b1d0-6414e52f908b'
    ),
    perm('b8460c9e-43c8-4290-a0f8-8ce450867f09', 'send-to-email'),
    perm(
      '1502c3b6-db02-4010-ab27-a9a11f353ff0',
      'hp-send-to-email-edit-bcc-field',
      'b8460c9e-43c8-4290-a0f8-8ce450867f09'
    ),
    perm(
      'eb70fc52-81a7-4261-9978-f31c09ed87dc',
      'hp-send-to-email-edit-cc-field',
      'b8460c9e-43c8-4290-a0f8-8ce450867f09'
    ),
    perm(
      '5a707440-966b-4598-9c9b-29b08838d9a7',
      'hp-send-to-email-edit-from-field',
      'b8460c9e-43c8-4290-a0f8-8ce450867f09'
    ),
    perm(
      '1d073e3c-7bd1-4269-b029-4e26c17c65b8',
      'hp-send-to-email-edit-message-body',
      'b8460c9e-43c8-4290-a0f8-8ce450867f09'
    ),
    perm(
      'e56b78b2-5081-4473-9761-f90c79cca974',
      'hp-send-to-email-edit-message-subject',
      'b8460c9e-43c8-4290-a0f8-8ce450867f09'
    ),
    perm(
      'b35a7519-0680-46cd-834c-3096ab8a8692',
      'hp-send-to-email-edit-to-field',
      'b8460c9e-43c8-4290-a0f8-8ce450867f09'
    ),
    perm(
      'd5e988c7-c39c-4e77-b53f-51c6fdea210a',
      'send-to-email-configure-default-job-settings',
      'b8460c9e-43c8-4290-a0f8-8ce450867f09'
    ),
    perm(
      'd8a96619-9aba-4249-afac-b2db90d0ca8b',
      'send-to-email-load-quicksets',
      'b8460c9e-43c8-4290-a0f8-8ce450867f09'
    ),
    perm(
      '5af2a754-d27c-40a4-a56d-2e7191def383',
      'send-to-email-save-quicksets',
      'b8460c9e-43c8-4290-a0f8-8ce450867f09'
    ),
    perm('09866970-7133-404f-bb20-440b9148e8e2', 'send-to-usb-mass-storage'),
    perm(
      '00aed7aa-fc3b-4d18-81dd-e46365576f6a',
      'send-to-usb-mass-storage-configure-job-settings',
      '09866970-7133-404f-bb20-440b9148e8e2'
    ),
    perm(
      '1bd63e89-82c9-4360-82c6-1fe9b4241247',
      'send-to-usb-mass-storage-load-quicksets',
      '09866970-7133-404f-bb20-440b9148e8e2'
    ),
    perm(
      '4044fe20-84a3-4314-a708-35626144609b',
      'send-to-usb-mass-storage-save-quicksets',
      '09866970-7133-404f-bb20-440b9148e8e2'
    ),
    perm('0B6044EA-3851-440A-94B8-0C6790729201', 'system-health-apps'),
    perm('a5e59604-d216-4977-a901-4774fcacbcb4', 'supply-status'),
    perm('Not available', 'tray-status'),
    perm(
      'e402dfff-566a-45c9-a0d3-18350436666e',
      'tray-status-modify-trays',
      'Not available'
    )
  ]

  static ewsPermissions = [
    perm('8e03369c-5e1d-4db2-aa95-f8aa176ad4dd', 'use-ews-information'),
    perm(
      '286b34e5-c683-41f7-97db-d0789d09d8fb',
      'use-ews-device-status',
      '8e03369c-5e1d-4db2-aa95-f8aa176ad4dd'
    ),
    perm(
      '862a91f4-7f00-4e1d-9c9e-ca39fb96625f',
      'use-ews-job-log-report',
      '8e03369c-5e1d-4db2-aa95-f8aa176ad4dd'
    ),
    perm(
      '4195be24-b6b9-4606-8231-e9f0fbe58082',
      'use-ews-status-information-pages',
      '8e03369c-5e1d-4db2-aa95-f8aa176ad4dd'
    ),
    perm(
      'b64ab78b-109c-4d28-bbaa-9d38b170f39c',
      'use-ews-print',
      '8e03369c-5e1d-4db2-aa95-f8aa176ad4dd'
    ),
    perm(
      '03b40393-3f56-497d-b625-c4e9fe947cbb',
      'remote-control-panel',
      '8e03369c-5e1d-4db2-aa95-f8aa176ad4dd'
    ),
    perm(
      '1228a6e0-7bc4-43c3-bd7a-7c9a41403416',
      'use-ews-print-internal-pages',
      '8e03369c-5e1d-4db2-aa95-f8aa176ad4dd'
    ),
    perm('8105cca1-dca3-498b-9851-e30e3c86c604', 'use-ews-settings'),
    perm(
      '35cd52af-9c9f-41d5-9127-3d8257192fe7',
      'home-screen-customization',
      '8105cca1-dca3-498b-9851-e30e3c86c604'
    ),
    perm(
      '313062db-30b2-46c7-aee2-d17b306358d1',
      'use-ews-display-customization-pages',
      '8105cca1-dca3-498b-9851-e30e3c86c604'
    ),
    perm(
      'b0b58bbd-0aaa-4aa4-8376-4c7d357b30eb',
      'use-ews-quick-sets',
      '8105cca1-dca3-498b-9851-e30e3c86c604'
    ),
    perm(
      'ab2a46af-9e0e-40e6-8f78-6f2d90552e96',
      'use-ews-alerts',
      '8105cca1-dca3-498b-9851-e30e3c86c604'
    ),
    perm(
      '623737ae-245f-48b9-a920-49c62d12381e',
      'use-ews-menu-tree',
      '8105cca1-dca3-498b-9851-e30e3c86c604'
    ),
    perm(
      'c8e0feff-e9be-471a-8c52-75036e7ddabe',
      'use-ews-auto-send',
      '8105cca1-dca3-498b-9851-e30e3c86c604'
    ),
    perm(
      '236906f8-2a77-4c74-b798-db600b8d5b21',
      'use-ews-real-time-clock',
      '8105cca1-dca3-498b-9851-e30e3c86c604'
    ),
    perm(
      'f9e0ead2-72fd-4c05-abfd-afb9c7856674',
      'use-ews-sleep-schedule',
      '8105cca1-dca3-498b-9851-e30e3c86c604'
    ),
    perm(
      '6646e5ba-44cf-4bbe-9965-43a7207575d2',
      'use-ews-backup-and-restore',
      '8105cca1-dca3-498b-9851-e30e3c86c604'
    ),
    perm(
      'b14973a4-292d-4a87-8601-418da34c7d84',
      'use-ews-reset-factory-defaults',
      '8105cca1-dca3-498b-9851-e30e3c86c604'
    ),
    perm(
      '75ede14f-6df7-402e-83f6-935fe361fa7c',
      'use-ews-solution-installer',
      '8105cca1-dca3-498b-9851-e30e3c86c604'
    ),
    perm(
      '2022f59d-6345-4112-8757-d4d6f6f32cf1',
      'use-ews-quota-server',
      '8105cca1-dca3-498b-9851-e30e3c86c604'
    ),
    perm('183ad723-1ce3-4da1-999c-ba546a7dd7fa', 'use-ews-print-pages'),
    perm(
      'f346c979-3090-4b14-8202-d5411a61c7de',
      'use-ews-open-from-usb',
      '183ad723-1ce3-4da1-999c-ba546a7dd7fa'
    ),
    perm(
      '12f36ee6-d6dd-4213-96a7-7d4fb4ac67ae',
      'use-ews-manage-stored-jobs',
      '183ad723-1ce3-4da1-999c-ba546a7dd7fa'
    ),
    perm(
      '0fc15b0b-005e-46b9-b8d3-5e681e75eab4',
      'use-ews-general-print-settings',
      '183ad723-1ce3-4da1-999c-ba546a7dd7fa'
    ),
    perm(
      '032dde52-eba1-4ee4-97bc-2a6c2c492f78',
      'use-ews-adjust-paper-types',
      '183ad723-1ce3-4da1-999c-ba546a7dd7fa'
    ),
    perm(
      '4e2c5b64-0b2e-45e6-b4b2-697ae54783ba',
      'use-ews-copy',
      '183ad723-1ce3-4da1-999c-ba546a7dd7fa'
    ),
    perm(
      '19a2b782-b1f6-4719-b983-820b8d5ee43c',
      'use-ews-tray-settings',
      '183ad723-1ce3-4da1-999c-ba546a7dd7fa'
    ),
    perm('03fe3bc9-85aa-4331-b8f6-633842527cc9', 'use-ews-send-pages'),
    perm(
      'c925c01b-a82d-4b09-bb92-25d2be2dc55c',
      'use-ews-send',
      '03fe3bc9-85aa-4331-b8f6-633842527cc9'
    ),
    perm(
      'a14f9e65-562d-4a54-92e9-cac2aa2a3b02',
      'use-ews-folder',
      '03fe3bc9-85aa-4331-b8f6-633842527cc9'
    ),
    perm(
      'b54ffebc-fe7e-4df1-b9cf-949699ecb4bc',
      'use-ews-share-point-folder',
      '03fe3bc9-85aa-4331-b8f6-633842527cc9'
    ),
    perm(
      '4cbd5f66-70e6-4aef-87fe-c095654de906',
      'use-ews-usb',
      '03fe3bc9-85aa-4331-b8f6-633842527cc9'
    ),
    perm(
      'de440e2f-c0ef-4dc0-8be1-9ac46e5f8936',
      'use-ews-address-book',
      '03fe3bc9-85aa-4331-b8f6-633842527cc9'
    ),
    perm(
      '8c15fe0e-9bc9-4ba7-aa6f-1f435267b8c6',
      'use-ews-digital-send-setup-wizard',
      '03fe3bc9-85aa-4331-b8f6-633842527cc9'
    ),
    perm(
      '57322a3d-805c-45f1-a384-3e0b81f52977',
      'use-ews-dss-settings',
      '03fe3bc9-85aa-4331-b8f6-633842527cc9'
    ),
    perm('7fa7a12e-2df5-4dee-9849-55baecb89f06', 'use-ews-fax-pages'),
    perm(
      '15b704f8-2a01-43bf-bc13-3356526291a6',
      'use-ews-fax-send-setup',
      '7fa7a12e-2df5-4dee-9849-55baecb89f06'
    ),
    perm(
      '8f85e851-3ac9-4721-a2f0-406e03b7172b',
      'use-ews-speed-dials',
      '7fa7a12e-2df5-4dee-9849-55baecb89f06'
    ),
    perm(
      'd526371c-57a7-4018-b88a-3751d14560e7',
      'use-ews-receive-fax-setup',
      '7fa7a12e-2df5-4dee-9849-55baecb89f06'
    ),
    perm(
      '80a6605f-b4ad-4f3d-a30d-fdd0b6dbd443',
      'use-ews-fax-archive-and-forwarding',
      '7fa7a12e-2df5-4dee-9849-55baecb89f06'
    ),
    perm(
      'd4cd0964-479e-401a-bc61-12b9f91be0ca',
      'use-ews-fax-activity-log',
      '7fa7a12e-2df5-4dee-9849-55baecb89f06'
    ),
    perm('7452e5a1-2537-422b-9424-8c4614561d4e', 'use-ews-supplies'),
    perm('c9b33fe6-fc66-4cf3-b206-1961b1b8bd09', 'use-ews-troubleshooting'),
    perm(
      '50d3cdc8-92d9-416d-abc4-15ef48353ac9',
      'use-ews-firmware-upgrade',
      'c9b33fe6-fc66-4cf3-b206-1961b1b8bd09'
    ),
    perm(
      '9a67e94b-38dc-4e1f-b83f-1f76919ad1fc',
      'use-ews-reports-and-tests',
      'c9b33fe6-fc66-4cf3-b206-1961b1b8bd09'
    ),
    perm(
      '030c7f90-a030-4ab9-8b0d-e4157eaefe33',
      'use-ews-online-solutions',
      'c9b33fe6-fc66-4cf3-b206-1961b1b8bd09'
    ),
    perm(
      '9f7b6180-7fa1-49f0-b949-4d2e212759b7',
      'use-ews-retrieve-diagnostic-data',
      'c9b33fe6-fc66-4cf3-b206-1961b1b8bd09'
    ),
    perm('ce752501-2c37-4970-86ca-a71c6278d7c9', 'use-ews-security'),
    perm(
      'e7092690-73ad-41c7-82e4-69b3303f5b1c',
      'use-ews-general-security',
      'ce752501-2c37-4970-86ca-a71c6278d7c9'
    ),
    perm(
      '7e6233fe-d9fe-42a2-8aa2-54d4cee0fd92',
      'use-ews-access-control-setup',
      'ce752501-2c37-4970-86ca-a71c6278d7c9'
    ),
    perm(
      '915f2966-e444-4bf5-bfc9-a1426d69d70a',
      'use-ews-protect-stored-data',
      'ce752501-2c37-4970-86ca-a71c6278d7c9'
    ),
    perm(
      '003A8AF8-284F-4c92-9990-969B1CB14AF5',
      'use-ews-remote-app-management-tabs',
      'ce752501-2c37-4970-86ca-a71c6278d7c9'
    ),
    perm(
      '65b90df4-b001-4dfc-8e8f-a417ca6d1c05',
      'use-ews-certificates-tabs',
      'ce752501-2c37-4970-86ca-a71c6278d7c9'
    ),
    perm(
      '0f176eaf-536d-40e9-8d67-9b4ca0a4e9e3',
      'use-ews-email-domains-restriction',
      'ce752501-2c37-4970-86ca-a71c6278d7c9'
    ),
    perm(
      '3e3a2318-8e7d-43ae-b091-16f26f197d0c',
      'use-ews-web-services-security',
      'ce752501-2c37-4970-86ca-a71c6278d7c9'
    ),
    perm(
      'fe81f04e-a67f-4036-9f44-45ab8c51e298',
      'use-ews-hp-web-services-pages'
    ),
    perm('6a8ce243-4b14-4940-a00f-d80fe4532f48', 'use-ews-networking')
  ]

  static allPermissions = [
    ...DevicePermissionItems.controlPanelPermissions,
    ...DevicePermissionItems.ewsPermissions
  ]

  static getPermissions = (constraints?: PolicyConstraints): PermItem[] =>
    !constraints
      ? DevicePermissionItems.allPermissions
      : constraints.filterSettingOptions(
          DevicePermissionItems.allPermissions,
          ConstraintsResourceEnum.rbacCfg,
          ConstraintsSettingEnum.rolePermissionMap
        )
}
