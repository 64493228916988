import styled from 'styled-components'

export const ReportSearchBoxMobileView = styled.div`
  width: 272px;
  margin-right: 12px;
`
export const ReportSearchBoxNormalView = styled.div`
  position: absolute;
  width:  87vw;
  margin-top: 7px
`
